import * as React from "react"

const SvgComponent = ({strokeColor="#A50000", 
backgroundColor="white",
width="70%", 
height="70%"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="106mm"
    height="152mm"
    viewBox="0 0 106 152"
    style={{
      height: height,
      width: width
    }}
  >
    <path
      style={{
        opacity: 1,
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      d="M53.105 1.903c-31.99-.142-36.598 39.644-34.77 40.265C20.09.068 60.129-8.219 78.996 14.482c-5.252-6.826-13.2-12.283-25.13-12.568-.255-.006-.508-.01-.76-.01zm25.89 12.579c5.497 7.141 8.04 15.777 9.09 21.568-1.277-8.936-4.577-16.139-9.09-21.568Zm9.09 21.568c.296 2.073.486 4.238.554 6.497.187-.07.142-2.66-.554-6.497zM53.387 12.72c-28.128 2.54-28.942 26.471-26.945 48l-.009-17.697c-3.592-1.426-10.768-.324-11.938.838-2.05 7.84-1.504 9.698-.613 16.202 6.058 2.405 6.504 1.604 12.562.802l-.002-.146c2.297 14.504 9.785 24.11 26.194 24.745 18.402.712 26.447-11.674 28.04-29.199 1.81-24.162-4.234-41.865-27.289-43.545Zm27.288 38.632c.104 1.099.255 7.205.447 8.6 6.058 1.07 5.969.535 12.027-.266.623-9.266 1.514-6.237 0-15.502-7.212-1.276-5.762-1.89-10.958-.802 0 0-1.248 6.474-1.516 7.97zM64.93 91.333l-.763-3.484c.456 4.696-.314 13.078-10.195 13.908-13.4 1.125-10.423-17.105-10.423-17.105-.11-.188-.205-.28-.284-.292-.767-.112-.165 7.276-.165 7.276-30.208 9.748-45.528 11.725-39.909 58.23l20.58.133 57.266-.033 23.717-.1c-.463-20.73.99-32.51-6.418-41.456-8.29-8.056-10.631-9.854-33.406-17.077Zm-.763-3.484c-.258-2.655-.907-4.136-.525-2.393z"
    />
    <path
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 1.779,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="M88.072 61.067s1.815 8.68-1.511 10.962c-5.632 3.86-30.995 2.646-30.995 2.646s32.506 2.837 32.506-5.292z"
    />
    <rect
      style={{
        opacity: 1,
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 1.779,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      width={0.802}
      height={30.201}
      x={23.504}
      y={119.931}
      ry={0.033}
    />
    <rect
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 1.779,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      width={0.802}
      height={30.201}
      x={82.437}
      y={120.065}
      ry={0.033}
    />
    <rect
      style={{
        opacity: 1,
        fill: strokeColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 1.779,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      width={3.207}
      height={3.475}
      x={38.471}
      y={54.718}
      ry={0.033}
    />
    <rect
      style={{
        fill: strokeColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 1.779,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      width={3.207}
      height={3.475}
      x={65.465}
      y={54.851}
      ry={0.033}
    />
    <path
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 1.779,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="M26.433 43.023c12.886 1.234 29.24 4.206 37.696-10.756C69.075 41 76.799 46.944 82.19 43.382c-4.943 3.12-10.981-.693-18.062-11.115-9.591 15.978-24.016 11.814-37.696 10.756Z"
    />
  </svg>
)

export default SvgComponent
