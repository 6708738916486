import React from "react";

function Icon({width, height,style}) {
  return (
    <svg 
style={style}
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg" viewBox="2.167 2.687 98.288 84.367">
      <path
        fill="rgba(255, 255, 255, 0)"
        stroke="#A50000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.379"
        d="M66.977 84.018c17.159 0 31.069-14.095 31.069-31.484 0-17.387-13.91-31.483-31.069-31.483-17.158 0-31.067 14.096-31.067 31.483 0 17.389 13.909 31.484 31.067 31.484z"
      ></path>
      <path
        fill="rgba(255, 255, 255, 0)"
        stroke="#A50000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.379"
        d="M56.982 7.312h19.289v6.291h-6.752l-.021 7.608-4.229-.382-.045-7.396-8.242.17V7.312z"
      ></path>
      <path
        fill="rgba(255, 255, 255, 0)"
        stroke="#A50000"
        strokeLinecap="round"
        strokeWidth="2.073"
        d="M36.959 27.831c-.532-.854-.93-4.223 1.422-6.502 2.594-2.513 5.068-2.244 6.798-.795 0 0-2.867 1.958-4.161 3.097-1.468 1.287-4.059 4.2-4.059 4.2zM87.367 20.281c.803-.627 4.191-1.458 6.835.497 2.916 2.154 2.971 4.57 1.723 6.426 0 0-2.385-2.494-3.722-3.586-1.514-1.237-4.836-3.337-4.836-3.337h0zM71.549 52.462c0 2.645-2.121 4.793-4.749 4.808a4.78 4.78 0 01-4.799-4.755c-.013-3.277 3.429-4.472 3.429-4.472 1.074-4.743-.638-12.864 1.391-12.891 1.777-.024.426 8.905 1.015 12.72 0 0 3.838.517 3.711 4.486l-4.772.104h4.774zM28.666 36.043H11.131c-.627 0-1.138.495-1.138 1.106 0 .609.511 1.105 1.138 1.105h17.535c.631 0 1.139-.496 1.139-1.105 0-.611-.508-1.106-1.139-1.106z"
      ></path>
      <path
        fill="rgba(255, 255, 255, 0)"
        stroke="#A50000"
        strokeLinecap="round"
        strokeWidth="2.168"
        d="M25.422 51.472H5.083c-.605 0-1.096.476-1.096 1.062 0 .587.491 1.063 1.096 1.063h20.339c.605 0 1.096-.476 1.096-1.063 0-.586-.491-1.062-1.096-1.062z"
      ></path>
      <path
        fill="rgba(255, 255, 255, 0)"
        stroke="#A50000"
        strokeLinecap="round"
        strokeWidth="2.073"
        d="M28.929 66.73H11.394c-.629 0-1.139.496-1.139 1.105 0 .611.51 1.105 1.139 1.105h17.535c.629 0 1.139-.494 1.139-1.105 0-.609-.51-1.105-1.139-1.105z"
      ></path>
    </svg>
  );
}

export default Icon;
