import React, { useEffect} from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import About  from "./pages/about/index.js"
import ContacUs  from "./pages/contact-us/index.js"
import Home  from "./pages/home/index.js"
import Services  from "./pages/services/index.js"
import Proposal  from "./pages/proposal/index.js"
import ProposalSuccess  from "./pages/proposal/success.js"
import Frame from "./pages/global/Frame"
import InternalServerError from "./pages/errors/500"




import "./style/app.scss" 
import 'bootstrap/dist/css/bootstrap.min.css';
import "./icofont.min.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";





export default function App() {
  document.title = "Gestion Immobilière - MesApparts"
  return (
    <div>
      <Frame>


      <Switch>

        <Route path="/apropos">
          <About />
        </Route>

        <Route path="/contactez-nous">
          <ContacUs />
        </Route>

        <Route path="/confirmation-reception">
          <ProposalSuccess/>
        </Route>

        <Route path="/nos-services">
          <Services />
        </Route>


        <Route path="/nos-services">
          <Services />
        </Route>



        <Route  path="/soumission">
          
            <Proposal />
          
        </Route>

        <Route path="/soumission-reussie">
          <ProposalSuccess/>
        </Route>

        <Route path="/500">
          <InternalServerError />
        </Route>
       
        <Route path="/">
          <Home />
        </Route>

     

      </Switch>

      </Frame>
    </div>
  );
}

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);