import * as React from "react"

const SvgComponent = ({strokeColor="#A50000", 
backgroundColor="white",
width="70%", 
height="70%"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="113mm"
    height="129mm"
    viewBox="0 0 113 129"
    style={{
      height: height,
      width: width
    }}
  >
    <rect
      style={{
        opacity: 1,
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={82.021}
      height={116.606}
      x={14.825}
      y={10.807}
      ry={2.112}
    />
    <rect
      style={{
        opacity: 1,
        fill: "#000",
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={19.466}
      height={0.378}
      x={69.254}
      y={47.093}
      ry={0}
      rx={0}
    />
    <rect
      style={{
        opacity: 1,
        fill: "#000",
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={19.466}
      height={0.378}
      x={69.037}
      y={55.547}
      ry={0}
    />
    <rect
      style={{
        opacity: 1,
        fill: "#000",
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={19.466}
      height={0.378}
      x={69.159}
      y={64.102}
      ry={0}
    />
    <rect
      style={{
        opacity: 1,
        fill: "#000",
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={19.466}
      height={0.378}
      x={69.192}
      y={72.734}
      ry={0}
    />
    <rect
      style={{
        opacity: 1,
        fill: "#000",
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={1.768}
      height={1.596}
      x={61.068}
      y={46.527}
      ry={0.798}
      rx={0}
    />
    <rect
      style={{
        opacity: 1,
        fill: "#000",
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={1.768}
      height={1.596}
      x={61.099}
      y={55.044}
      ry={0}
      rx={0}
    />
    <rect
      style={{
        opacity: 1,
        fill: "#000",
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={1.768}
      height={1.596}
      x={61.016}
      y={63.563}
      ry={0}
      rx={0}
    />
    <rect
      style={{
        opacity: 1,
        fill: "#000",
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={1.768}
      height={1.596}
      x={61.099}
      y={72.133}
      ry={0}
      rx={0}
    />
    <ellipse
      style={{
        opacity: 1,
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      cx={43.457}
      cy={99.254}
      rx={16.634}
      ry={19.374}
    />
    <text
      xmlSpace="preserve"
      style={{
        fontSize: "34.9738px",
        lineHeight: 1.25,
        fontFamily: "Constantia",
        InkscapeFontSpecification: "Constantia",
        fill: strokeColor,
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 0.979,
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      x={30.829}
      y={120.092}
      transform="scale(1.09128 .91636)"
    >
      <tspan
        x={30.829}
        y={120.092}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontFamily: "Cambria",
          InkscapeFontSpecification: "Cambria",
          fill: strokeColor,
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.979,
          strokeMiterlimit: 1.71429,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      >
        {"$"}
      </tspan>
    </text>
    <path
      style={{
        opacity: 1,
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      d="M110.13 69.81c2.557 2.424.662 4.65-.08 5.733l-32.173 37.739c-.664.78-9.422 5.997-10.419 5.82l-.31-.055c-.996-.176 4.156-10.258 4.82-11.038l32.173-37.74c.664-.78 3.535-2.785 5.989-.46z"
    />
    <rect
      style={{
        opacity: 1,
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.37931,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={7.866}
      height={5.335}
      x={124.779}
      y={-12.996}
      ry={0}
      transform="matrix(.74609 .66585 -.64874 .761 0 0)"
    />
    <rect
      style={{
        opacity: 1,
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={49.515}
      height={70.115}
      x={1.785}
      y={1.547}
      ry={6.426}
    />
    <rect
      style={{
        opacity: 1,
        fill: "none",
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={37.231}
      height={10.772}
      x={7.833}
      y={7.972}
      ry={0}
    />
    <rect
      style={{
        opacity: 1,
        fill: "none",
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={7.56}
      height={9.116}
      x={9.723}
      y={25.315}
      ry={1.982}
    />
    <rect
      style={{
        fill: "none",
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={7.56}
      height={9.116}
      x={22.763}
      y={25.337}
      ry={1.982}
    />
    <rect
      style={{
        fill: "none",
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={7.56}
      height={9.116}
      x={35.992}
      y={25.526}
      ry={1.982}
    />
    <rect
      style={{
        fill: "none",
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={7.56}
      height={9.116}
      x={9.912}
      y={40.267}
      ry={1.982}
    />
    <rect
      style={{
        fill: "none",
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={7.56}
      height={9.116}
      x={22.763}
      y={40.645}
      ry={1.982}
    />
    <rect
      style={{
        fill: "none",
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={7.56}
      height={9.116}
      x={35.992}
      y={40.456}
      ry={1.982}
    />
    <rect
      style={{
        fill: "none",
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={7.56}
      height={9.116}
      x={10.101}
      y={55.764}
      ry={1.982}
    />
    <rect
      style={{
        fill: "none",
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={7.56}
      height={9.116}
      x={22.763}
      y={55.575}
      ry={1.982}
    />
    <rect
      style={{
        fill: "none",
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 1.71429,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      width={7.56}
      height={9.116}
      x={35.614}
      y={55.764}
      ry={1.982}
    />
  </svg>
)

export default SvgComponent
