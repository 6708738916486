import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';
import useWindowSize from "../../hooks/useWindowSize"




export default function Header({image, title, style}){
    const size = useWindowSize()
    return(

             
        <div style={{ textAlign: 'center', marginBottom: '100px', ...style}}>
        
        <ParallaxBanner
            disabled={size.width > 950 ? false: true}
            layers={[
            {
            image:  image,
            amount: 0.8,
            }
      
        ]}
        style={{

            display: 'flex', 
            justifyContent: 'flex-start', 
            alignItems: 'center',
            flex: 1,
        }}
        >
                        <div 
                        className="title"
                        style={{
                position: 'absolute',
                color: 'white', 
                justifyContent: 'left',
                fontFamily: 'Arial',
                textShadow: "4px -1px 8px rgba(56, 56, 56, 1)",
                width: '100%'


                
                
                }}>
            <h2 style={{  
            fontSize: '6vh', 
            fontWeight: 'bold',  
            color: 'white', 
            padding: '5vh 1vh', 
            marginLeft: '5%',
            marginRight: '5%',

            textAlign: 'left',
            width: '50%',

            }}>
                {title}
            </h2>
            </div>

    </ParallaxBanner>

    </div>

    )
}