
import Button from "../global/Button"
import useWindowSize from "../../hooks/useWindowSize"

export default function Footer(){

    const size = useWindowSize()

    return (            
    
    
    <footer style={{
    width: '100%',
    backgroundColor: '#a51d26', 
    position: 'relative', 
    bottom: '0', 
    color: 'white',
    fontSize: '1.1em',
    display:'flex', 
    flexWrap: 'wrap',
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"
    }}>

    

        <div
        style={{
            width: '100%',
            display: 'flex', 
            justifyContent: 'space-evenly', 
            padding: '50px',
            flexWrap: 'wrap',
            

        }}
        >
             <ul
             id="informations"
                   style={{
                    listStyle: 'none',
                    textAlign: 'left',
                    padding: '0', 
                    maxWidth: '320px'



                }}
            >   

               <li
               style={{
                    fontWeight:'bold',
                     textAlign: size.width <= 1100 ? "center" : "left",
                    padding: '10px'

                }}
               >INFORMATIONS</li>

                <li
                     style={{padding: '10px'}}
                ><i class="icofont-google-map"></i> <a href="https://goo.gl/maps/h8wSAHsCc51ejpAi7">213-630 Rue Francis-Byrne, QC, G1H 7N4</a> </li>

                <li
                     style={{padding: '10px'}}
                ><i class="icofont-phone"></i> <a href="tel:581-443-1417">(581) 443-1417</a></li>
                <li
                     style={{padding: '10px'}}
                ><i 
                
                class="icofont-mail"></i> <a 
                className="mail-to"
                title="Adresse Courriel de MesApparts" 
                href="mailto:info@mesapparts.ca"
                >info@mesapparts.ca</a></li>

            </ul>
            


            <div
                id="map"
           
            >

                <span
                style={{textAlign: size.width <= 1100 ? "center" : "left",
                        display: 'block',
                        fontWeight:'bold',

                        padding: '10px',
                    

                        }}
                >NAVIGATION</span>
                <div
                style={{display: 'flex',
            }}
                >
                    <ul
                    style={{
                        listStyle: 'none',
                        textAlign: 'left',
                        padding: '0', 

                    }}>
                         
                        

                        <li
                        style={{padding: '10px'}}
                        ><a href="/accueil" >ACCUEIL</a></li>
                        <li
                        style={{padding: '10px'}}
                        ><a
                        href="/nos-services"
                        >NOS SERVICES</a></li>
                        <li
                        style={{padding: '10px'}}
                        >
                            <a
                             href="/soumission"
                            >
                            SOUMISSION
                            </a>
                        </li>
                        {/* 
                        <li
                        style={{padding: '10px'}}
                        >
                        <a
                        href="/accueil"
                        >LOCATAIRES</a></li>*/}
                       
                    </ul>

                    <ul
                     style={{
                        listStyle: 'none',
                        textAlign: 'left',
                        padding: '0', 
                        margin : '0',
                    }}
                    >
        
                    <li
                    style={{padding: '10px'}}
                    ><a
                    href="/contactez-nous"
                    >CONTACTEZ-NOUS</a></li>
                    <li
                    style={{padding: '10px'}}
                    >
                      <a
                              href="/apropos"
                      >
                        À PROPOS
                        </a>  
                        </li>
                    </ul>

                    </div>

                    </div>


    <div id="newsletter"
        style={{
            display: 'flex',
            justifyContent: 'flex-start',
          
        }}
        >

            <form 
            style={{display: 'flex', 
            flexDirection: 'column',
}}
            for="#" method="post">
                <span
                style={{

                        display: 'block',
                        fontWeight:'bold',
                        textAlign: size.width <= 1100 ? "center" : "left"

                }}
                >NEWSLETTER</span>
            <span
            style={{padding: '10px 0'}}
            >Recevez les denières nouvelles</span>

                <label style={{marginRight: '20px'}}>Email :</label>
                <input type="email"></input>
                
                <Button.Submit
                style={{margin: '5px 0'}}
                type="submit"/>
            </form>

        </div>
        </div>

    
    
    

    
    <div
style={{
    width: '100%',
    textAlign: 'left',
    display: 'block',
    bottom: '0',
    padding: '0 50px',
    


}}>
      
        <p
        style={{borderTop: '1px solid white',
    padding: '20px'}}
        >© 2021 - Copyright MesApparts</p>
</div>


    </footer> )
}