import { Reat, useState, title } from "react"

import ServiceAssembleDeCopropriete from "../images/services/picto/s_ass_de_coop"
import ServiceDeConciergerie from "../images/services/picto/s_conciergerie"
import ServiceConseilProfessionnel from "../images/services/picto/s_conseil_professionnel"
import ServiceDeGestionLocative from "../images/services/picto/s_gestion_locative"
import ServiceDePageWebPublicitaire from "../images/services/picto/s_page_web_pub"
import ServiceAdmin from "../images/services/picto/s_service_admin"
import ServiceDeCommunication from "../images/services/picto/s_service_de_com"
import ServiceDeGestionDesTravaux from "../images/services/picto/s_gestion_des_travaux"
import ServiceEntretienExterieur from "../images/services/picto/s_entretien_exterieur"
import ServiceEntretienInterieur from "../images/services/picto/s_entretien_interieur"
import ServiceGestionFinances from "../images/services/picto/s_gestion_des_finances"
import ServiceDePublicite from "../images/services/picto/s_service_de_pub"
import ServiceDeTravaux from "../images/services/picto/s_travaux"
import ServiceDeCompta from "../images/services/picto/s_service_compta"
import ServiceDeGestion from "../images/services/picto/s_gestion_immobiliere"


const SERVICES = {
    
    ASSEMBLEE_DE_COPROPRIETE : {
        picto : ServiceAssembleDeCopropriete,
        header : () => (<h3>ASSEMBLÉE DE <span className="subtitle">COPROPRIÉTÉS</span></h3>),
        model : {
        strokeColor: "#a50000",
        backgroundColor: "white",

        strokeColorIn: "white",
        backgroundColorIn: "#a50000",

        strokeColorOut: "#a50000",
        backgroundColorOut: "white",

        modal : {

            title : {
                simple : "Assemblée de",
                emphasis : "copropriétés"
            },

            tasks : [
                    "Avis de convocation",
                    "Procurations",
                    "Rencontres de préparation",
                    "Bulletin de vote",
                    "Autres tâches administratives",
                    "Animation",
                    "Rédaction des procès-verbaux",
            ],

            picto : "ServiceCopropriete"
            
        }
    }

     

    },
    GESTION_DES_TRAVAUX : {

        picto : ServiceDeGestionDesTravaux,
        header : () => (<h3>GESTION <span className="subtitle"> DES TRAVAUX</span></h3>),
        model : {
        strokeColor: "#a50000",
        backgroundColor: "white",

        strokeColorIn: "white",
        backgroundColorIn: "#a50000",

        strokeColorOut: "#a50000",
        backgroundColorOut: "white",

        modal : {

            title : {
                simple : "Gestion",
                emphasis : "des travaux"
            },

            tasks : [
                "Répondre aux appels d’urgence 24h/7jr",
                "Superviser les réparations mineures et urgentes",
                "Recherche de soumission",
                "Gestion des sous-traitants",
                "Évaluation des entreprises et des entrepreneurs",
                "Superviser les travaux majeurs.",
            ],

            picto : "ServiceGestionDesTravaux"
            
        }
    }
    },
    ENTRETIEN_EXTERIEUR : {

        picto : ServiceEntretienExterieur,
        header : () => (<h3>ENTRETIEN <span className="subtitle"> EXTÉRIEUR</span></h3>),
        model : {
        strokeColor: "white",
        backgroundColor: "#a50000",

        strokeColorIn: "#a50000",
        backgroundColorIn: "white",

        strokeColorOut: "white",
        backgroundColorOut: "#a50000",

        modal : {
          

            title : {
                simple : "Entretien",
                emphasis : "extérieur"
            },

            tasks : [
                
                "- NETTOYAGE -",
                "Nettoyer les margelles",
                "Laver les stationnements extérieurs",
                "Laver les fenêtres",
                "Nettoyer le garage/cabanon",
                "Entretien de la végétation",
                "Nettoyage des surfaces extérieurs",
                "Nettoyer les grilles des stationnements extérieurs",
                "Nettoyer les cadres extérieurs de fenêtres",
                "Nettoyage des gouttières",
                "_________________________",
                "- JARDINAGE - ",
                "Ramasser les feuilles mortes",
                "Couper et entretenir la pelouse",
                "Nettoyer la pelouse au printemps",
                "Attacher les arbustes",
                "_________________________",
                "- DÉNEIGEMENT - ",
                "Déneiger les entrées",
                "Déneiger les sorties de secours",
                "Déneiger les bornes fontaines",
                "Déneiger le toit",
                "Déneiger les abris",
                "Mettre des abrasifs",
                "Monter les abris d’hiver",
                "Installer les boites à sel et les remplir",
                "_________________________",
                "- PISCINE - ",
                "Ouverture de piscine",
                "Fermeture de piscine",
                "Entretien majeur",
                "Entretien mineur",
                "Surveillant / sauveteur",
                "Respect de la réglementation",
            ],

            picto : "ServiceEntretetienExterieur"
        }
    }
    },
    ENTRETIEN_INTERIEUR : {

        picto : ServiceEntretienInterieur,
        header : () => (<h3>ENTRETIEN <span className="subtitle"> INTÉRIEUR</span></h3>),
        model :{
        strokeColor: "#a50000",
        backgroundColor: "white",

        strokeColorIn: "white",
        backgroundColorIn: "#a50000",

        strokeColorOut: "#a50000",
        backgroundColorOut: "white",
        modal : {
            title : {
                simple : "Entretien",
                emphasis : "intérieur"
            },
            tasks : [
                "Laver les vitres",
                "Passer l’aspirateur",
                "Laver les planchers",
                "Sortir les vidanges",
                "Vider les poubelles",
                "Nettoyer les salles de lavage",
                "Laver les salles de vidanges",
                "Laver les murs et boiseries",
                "Laver les surfaces",
                "Laver les tapis",
                "Laver la laveuse et la sécheuse",
                "Effectuer les achats d’entretien intérieur",
                "Installer les tapis pour l’hiver et les retirer au printemps",
                "Nettoyer les cadres intérieurs de fenêtres",
            ], 
            picto : "ServiceEntretetienInterieur"
        }
    }
    },
    GESTION_DES_FINANCES : {

        picto : ServiceGestionFinances,
        header :() => ( <h3>GESTION DES <span className="subtitle">FINANCES</span></h3>),
        model : {

        strokeColor: "#a50000",
        backgroundColor: "white",

        strokeColorIn: "white",
        backgroundColorIn: "#a50000",

        strokeColorOut: "#a50000",
        backgroundColorOut: "white",

        modal : {
            title : {
                simple : "Gestion",
                emphasis  : "des finances"
            },
            tasks : [
                "Contrôle financier",
                "Analyse financière",
                "Préparation annuel et suivi budgétaire",
                "Gestion des placements",
                "Conseil financier et fiscal",
                "Rapports d’impôts",
            ],
            picto : "ServiceGestionDesFinances"
        }
    }
    },
    CONCIERGERIE : {

        picto : ServiceDeConciergerie,
        header : () => ( <h3><span className="subtitle">CONCIERGERIE</span></h3>),
        model : {

        strokeColor: "white",
        backgroundColor: "#a50000",

        strokeColorIn: "#a50000",
        backgroundColorIn: "white",

        strokeColorOut: "white",
        backgroundColorOut: "#a50000",

        modal : {

            title : {
                simple : "",
                emphasis  : "Conciergerie"
            },

            tasks : [
                "Laver les vitres", 
                "Passer l’aspirateur", 
                "Laver les planchers", 
                "Sortir les vidanges", 
                "Vider les poubelles", 
                "Nettoyer les salles de lavage", 
                "Laver les salles de vidanges", 
                "Mettre les bacs à la rue", 
                "Laver les murs et boiseries", 
                "Laver les surfaces", 
                "Laver les stationnements intérieurs", 
                "Laver les tapis", 
                "Laver la laveuse et la sécheuse", 
                "Nettoyer les grilles des stationnements intérieurs", 
                "Lubrifier les serrures et les portes", 
                "Nettoyer les conduits des chutes à déchets", 
                "Nettoyer les conduits d’aération des sécheuses et les sorties d’air", 
                "Nettoyer les filtres des systèmes d’aération", 
                "Nettoyer les radiateurs", 
                "Effectuer les achats d’entretien intérieur", 
                "Installation de pellicule divers", 
                "Lubrifier les valves", 
                "Lubrifier les bacs", 
                "Changer l’heure des minuteries", 
                "Remettre les divers éléments en marche lors de panne d’électricité", 
                "Entretenir l’équipement de travaux", 
                "Entretenir l’équipement d’entretien intérieur", 
                "Installer les tapis pour l’hiver et les retirer au printemps", 
                "Nettoyer les cadres intérieurs de fenêtres", 
                "Calfeutrage intérieur", 
                "Nettoyer les margelles", 
                "Laver les stationnements extérieurs", 
                "Laver les fenêtres", 
                "Nettoyer le garage/cabanon", 
                "Ramasser les feuilles mortes", 
                "Couper et entretenir la pelouse", 
                "Nettoyer la pelouse au printemps", 
                "Déneiger les entrées", 
                "Déneiger les sorties de secours", 
                "Déneiger les bornes fontaines", 
                "Déneiger le toit", 
                "Déneiger les abris", 
                "Mettre des abrasifs", 
                "Nettoyer les grilles des stationnements extérieurs", 
                "Effectuer les achats d’entretien extérieur", 
                "Monter les abris d’hiver", 
                "Entretien de la végétation", 
                "Nettoyage des surfaces extérieurs", 
                "Attacher les arbustes", 
                "Installer les boites à sel et les remplir", 
                "Entretenir l’équipement d’entretien extérieur", 
                "Nettoyer les cadres extérieurs de fenêtres", 
                "Calfeutrage extérieur", 
                "Nettoyage des gouttières", 
                "Rouvrir la piscine et entretenir la piscine", 
                "Effectuer des réparations/travaux mineures ", 
                "Changer les ampoules", 
                "Effectuer les achats pour effectuer les travaux si nécessaire", 
                "Réparer des murs", 
                "Peinture intérieure", 
                "Peinture extérieure", 
            ],
            picto : "ServiceDeConciergerie"
        }
    }
    },
    CONSEILS_PROFESIONNEL : {
        picto : ServiceConseilProfessionnel,
        header : () => (  <h3>CONSEILS <span className="subtitle"> PROFESSIONNELS</span></h3>),
        model : {

        strokeColor: "white",
        backgroundColor: "#a50000",

        strokeColorIn: "#a50000",
        backgroundColorIn: "white",

        strokeColorOut: "white",
        backgroundColorOut: "#a50000",

        modal : {
            title : {
                simple : "Conseils", 
                emphasis : "professionnels"
            },
            tasks : [
                "CPA",
                "Notaire",
                "Gestionnaire immobilier", 
                "etc."
            ],
            picto : "ServiceConseilProfessionnel"
        }
    }
    },
    SERVICE_DE_PUBLICITE : {

        picto : ServiceDePublicite,

        header : () =>  (  <h3>SERVICES DE <span className="subtitle">PUBLICITÉ</span></h3>),

        model : {

        strokeColor: "white",
        backgroundColor: "#a50000",

        strokeColorIn: "#a50000",
        backgroundColorIn: "white",

        strokeColorOut: "white",
        backgroundColorOut: "#a50000",

        modal : {
            title : {
                simple : "Service",
                emphasis  : "de publicité"
            },
            tasks : [
                "Prise de photos",
                "Montage d’une fiche d’offre de location",
                "Affichage sur la bâtisse et/ou balcon",
                "Publicités",
            ],
            picto : "ServiceDePublicite"
        }
    }
    },
    TRAVAUX : {

        picto : ServiceDeTravaux,
        header : () => (<h3><span className="subtitle">TRAVAUX</span></h3>),
        model : {

        strokeColor: "#a50000",
        backgroundColor: "white",

        strokeColorIn: "white",
        backgroundColorIn: "#a50000",

        strokeColorOut: "#a50000",
        backgroundColorOut: "white",

        modal : {
            title : {
                simple : "",
                emphasis : "Travaux"
            },
            tasks : [
                "Effectuer les travaux majeurs",
                "Effectuer les réparations urgentes",
                "Inspection",
                "Peinture des lignes de stationnement",
                "Effectuer les réparations/travaux mineures ",
                "Changer les ampoules",
                "Effectuer les achats pour effectuer les travaux si nécessaire",
                "Réparer les murs",
                "Peinture intérieure",
                "Peinture extérieure",
            ],
            picto : "ServiceTravaux"
        }
    }
    },
    SERVICE_COMPTABLE : {
        picto : ServiceDeCompta,
        header : () => ( <h3>SERVICES <span className="subtitle">COMPTABLES</span></h3>),
        model : {
        strokeColor: "#a50000",
        backgroundColor: "white",

        strokeColorIn: "white",
        backgroundColorIn: "#a50000",

        strokeColorOut: "#a50000",
        backgroundColorOut: "white",

        modal : {
            title : {
                simple : "Services",
                emphasis : "comptables"
            },
            tasks : [
                "Tenue des livres",
                "Compte à recevoir",
                "Compte à payer",
                "Conciliations bancaires",
                "États financiers",
                "Rapports financiers mensuels et annuels au propriétaire",
                "Rapports et paiements aux gouvernements",
                "Gestion de la paie"
            ],
            picto : "ServiceComptable"
        }
    }

    },
    SERVICE_ADMINISTRATIF : {

        picto : ServiceAdmin,
        header : () => (  <h3>SERVICES <span className="subtitle">ADMINISTRATIFS</span></h3>),
        model :{
        strokeColor: "#a50000",
        backgroundColor: "white",

        strokeColorIn: "white",
        backgroundColorIn: "#a50000",

        strokeColorOut: "#a50000",
        backgroundColorOut: "white",

        modal : {
            title : {
                simple : "Services", 
                emphasis : "administratifs"
            },
            tasks : [
                "Service à la clientèle", 
                "Secrétariat", 
                "Gestion des documents", 
                "Collectes, dépôts et rapports mensuels des loyers", 
                "Relevé 31", 
                "Mise à jour de base de données", 
                "Préparer et expédier les avis de renouvellements des baux", 
            ],
            picto : "ServiceAdministratif"
        }
    }
    },
    GESTION_LOCATIVE  : {

        picto : ServiceDeGestionLocative,
        header : () => (   <h3>GESTION <span className="subtitle">LOCATIVE</span></h3>),
        model : {

        strokeColor: "#a50000",
        backgroundColor: "white",

        strokeColorIn: "white",
        backgroundColorIn: "#a50000",

        strokeColorOut: "#a50000",
        backgroundColorOut: "white",


        modal : {
            title : {
                simple : "Gestion", 
                emphasis : "locative"
            },
            tasks : [
            "Constat des lieux" ,
            "Réception des demandes de visite et location" ,
            "Visite des prospectes" ,
            "Enquête de crédit et plus" ,
            "Signature du bail" ,
            "Remise des clés" ,
            "Suivi après aménagement" 
            ],
            picto : "GestionLocative"
        }
    }
    },
    PAGE_WEB_PUB : {

        picto : ServiceDePageWebPublicitaire,
        header  : () => (   <h3>PAGE <span className="subtitle">WEB PUBLICITAIRE</span></h3>),
        model : {

        strokeColor: "#a50000",
        backgroundColor: "white",

        strokeColorIn: "white",
        backgroundColorIn: "#a50000",

        strokeColorOut: "#a50000",
        backgroundColorOut: "white",

        modal : {
            title : {
                simple : "Page", 
                emphasis : "web publicitaire"
            },
            tasks : [
                "Faire le ‘design’ (conception)", 
                "Faire l’intégration (programmation)", 
                "Recherche de service et de base de données", 
                "Publication", 
                "Mise à jour et modification", 
            ],
            picto : "PageWebPublicitaire"
        }
    }
    },
    SERVICE_DE_COMMUNICATION : {

        picto : ServiceDeCommunication,
        header : () => ( <h3>SERVICE DE <span className="subtitle">COMMUNICATION</span></h3>),
        model : {

        strokeColor: "#a50000",
        backgroundColor: "white",

        strokeColorIn: "white",
        backgroundColorIn: "#a50000",

        strokeColorOut: "#a50000",
        backgroundColorOut: "white",

        modal : {
            title : {
                simple : "Service ", 
                emphasis : "de communication"
            },
            tasks : [
                    "Service d’appel 24 heures par jour, 7 jours par semaine" ,
                    "Gestion de la correspondance par courriels, logiciel de gestion et textos" ,
                    "Redirection des demandes et des plaintes" ,
            ],
            picto : "ServiceDeCommunication"
        }
    }
    },
    SERVICE_DE_GESTION : {

        picto : ServiceDeGestion,
        header : () => (<h3>SERVICE DE <span className="subtitle">GESTION</span></h3>),

        model : {

        strokeColor: "#a50000",
        backgroundColor: "white",

        strokeColorIn: "white",
        backgroundColorIn: "#a50000",

        strokeColorOut: "#a50000",
        backgroundColorOut: "white",

        modal : {
            title : {
                simple : "Service", 
                emphasis : "de gestion"
            },
            tasks : [
                "Réception des appels d’urgence 24 heures sur 24", 
                "Suivi des plaintes des locataires et / ou autorités gouvernementales",
                "Superviser le personnel;",
                "Suivi des loyers en délinquance et recouvrement",
                "Gestion des renouvèlements des baux",
                "Effectuer un suivi régulier de l’état des lieux;",
                "Rencontres de gestion",
                "Gestion des clés",
                "Gestion des assurances",
                "Programme d’entretien préventif",
                "Gestion des fournisseurs",
                "Respect des règlements",
                "Relations avec le tribunal administratif du logement",
                "Gestion des espaces de stationnement",
                "Gestion des espaces de rangement",
                "Gestion et affichage des boites aux lettres et des accès aux bâtiments" 
            ],
            picto : "ServiceDeGestionImmobiliere"
        }
    }
    },
    CARTE : {
        

        model :{
            modal : {
                picto : "ALaCarte",
                title : {
                    simple : "À la ",
                    emphasis : "carte"
                }
            }
        }
       
    }
}

const FORFAITS = {

    ESPRIT_TRANQUILLE  : {
        nom : "Je veux l'esprit tranquille",
        services : [
            {
                src : SERVICES.SERVICE_ADMINISTRATIF,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_DE_GESTION,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_DE_COMMUNICATION,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_DE_PUBLICITE,
                disponible : true
            },
            {
                src : SERVICES.GESTION_LOCATIVE,
                disponible : true
            },
            {
                src : SERVICES.GESTION_DES_TRAVAUX,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_COMPTABLE,
                disponible : true
            },
            {
                src : SERVICES.GESTION_DES_FINANCES,
                disponible : true
            },
            {
                src : SERVICES.CONCIERGERIE,
                disponible : true
            }
        ]
    }, 
    SANS_CONCIERGERIE : {
        nom : "J'ai un concierge",
        services : [
            {
                src : SERVICES.SERVICE_ADMINISTRATIF,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_DE_GESTION,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_DE_COMMUNICATION,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_DE_PUBLICITE,
                disponible : true
            },
            {
                src : SERVICES.GESTION_LOCATIVE,
                disponible : true
            },
            {
                src : SERVICES.GESTION_DES_TRAVAUX,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_COMPTABLE,
                disponible : true
            },
            {
                src : SERVICES.GESTION_DES_FINANCES,
                disponible : true
            },
            {
                src : SERVICES.CONCIERGERIE,
                disponible : false
            }
        ]
    }, 
    MANQUE_DE_TEMPS : {
        nom : "Je manque de temps",
        services : [
            {
                src : SERVICES.SERVICE_ADMINISTRATIF,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_DE_GESTION,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_DE_COMMUNICATION,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_DE_PUBLICITE,
                disponible : true
            },
            {
                src : SERVICES.GESTION_LOCATIVE,
                disponible : true
            },
            {
                src : SERVICES.GESTION_DES_TRAVAUX,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_COMPTABLE,
                disponible : false
            },
            {
                src : SERVICES.GESTION_DES_FINANCES,
                disponible : false
            },
            {
                src : SERVICES.CONCIERGERIE,
                disponible : true
            }
        ]
    },

    SANS_COMPTABLE : {
        nom : "J'ai mon comptable",
        services : [
            {
                src : SERVICES.SERVICE_ADMINISTRATIF,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_DE_GESTION,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_DE_COMMUNICATION,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_DE_PUBLICITE,
                disponible : true
            },
            {
                src : SERVICES.GESTION_LOCATIVE,
                disponible : true
            },
            {
                src : SERVICES.GESTION_DES_TRAVAUX,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_COMPTABLE,
                disponible : false
            },
            {
                src : SERVICES.GESTION_DES_FINANCES,
                disponible : false
            },
            {
                src : SERVICES.CONCIERGERIE,
                disponible : false
            }
        ]
    },

    SANS_ACTIFS : {
        nom : "Je gère mes actifs",
        services : [
            {
                src : SERVICES.SERVICE_ADMINISTRATIF,
                disponible : false
            },
            {
                src : SERVICES.SERVICE_DE_GESTION,
                disponible : false
            },
            {
                src : SERVICES.SERVICE_DE_COMMUNICATION,
                disponible : false
            },
            {
                src : SERVICES.SERVICE_DE_PUBLICITE,
                disponible : false
            },
            {
                src : SERVICES.GESTION_LOCATIVE,
                disponible : false
            },
            {
                src : SERVICES.GESTION_DES_TRAVAUX,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_COMPTABLE,
                disponible : false
            },
            {
                src : SERVICES.GESTION_DES_FINANCES,
                disponible : false
            },
            {
                src : SERVICES.CONCIERGERIE,
                disponible : true
            }
        ]
    },

    AVEC_LOCATAIRE :  {
        nom : "Trouvez-moi des locataires",
        services : [
            {
                src : SERVICES.SERVICE_ADMINISTRATIF,
                disponible : false
            },
            {
                src : SERVICES.SERVICE_DE_GESTION,
                disponible : false
            },
            {
                src : SERVICES.SERVICE_DE_COMMUNICATION,
                disponible : false
            },
            {
                src : SERVICES.SERVICE_DE_PUBLICITE,
                disponible : true
            },
            {
                src : SERVICES.GESTION_LOCATIVE,
                disponible : true
            },
            {
                src : SERVICES.GESTION_DES_TRAVAUX,
                disponible : true
            },
            {
                src : SERVICES.SERVICE_COMPTABLE,
                disponible : false
            },
            {
                src : SERVICES.GESTION_DES_FINANCES,
                disponible : false
            },
            {
                src : SERVICES.CONCIERGERIE,
                disponible : false
            }
        ]
    },

    BESOIN_DE_TOUT_CA : {
        nom : "À la carte",
        services : [
            {

                src : SERVICES.CARTE,
                disponible : true
            }
        ]
    }




}

function BasePicto({
    service,
    strokeColor,
    backgroundColor,
    strokeColorIn,
    strokeColorOut,
    backgroundColorIn,
    backgroundColorOut,
    modal,
    picto,
    header,
    handleOpenModal
    
}){
    
    const Picto = picto;
    const Header = header;

    let [state, setState] = useState({
        strokeColor: strokeColor,
        backgroundColor: backgroundColor,
        strokeColorIn: strokeColorIn,
        backgroundColorIn: backgroundColorIn,
        strokeColorOut: strokeColorOut,
        backgroundColorOut: backgroundColorOut,
        title : modal.title,
        tasks : modal.tasks
    })

    const handleColorSwapIn = () => {
        setState((oldState) => {
            
            let newState = {...oldState};
            newState.strokeColor = oldState.strokeColorIn
            newState.backgroundColor = oldState.backgroundColorIn

            return newState;
        })
    }

    const handleColorSwapOut = () => {
        setState((oldState) => {

            let newState = {...oldState};

            newState.strokeColor = oldState.strokeColorOut
            newState.backgroundColor = oldState.backgroundColorOut

            return newState;
        })
    }

    return (<div 
        onMouseEnter={handleColorSwapIn}
        onMouseLeave={handleColorSwapOut}
        onClick={() => {
            handleOpenModal({
                title  : state.title,
                tasks : state.tasks,
                picto : picto
            })
            handleColorSwapOut()
        }}

    className="service">
        <Picto
            {...state}
        />
        <Header/>
    </div>)
}


function Forfait({
    nom,
    services,
    className,
    onSelect
}) {

    



    return (<div 

    className={`forfait-container`} >

        <h3>{nom}</h3>

        <ul>
            {services.map(service => <ServiceNom  
                nom={service.src.model.modal.title.simple + " "+  service.src.model.modal.title.emphasis  }
                disponible={service.disponible}
            />)}
        </ul>

        <button 
        onClick={() => {
            onSelect(services)
        }}>Sélectionner</button>

    </div>)
}

function ServiceNom({
    nom, disponible = false
}) {
    return <p className="nom-service">
        {disponible ?
            <i className="icofont-tick-mark success"></i> : ""
        }
        <span className={
            !disponible ? "transluscide" :
                ""
        }>
            {nom}
        </span>
    </p>
}


export {SERVICES, FORFAITS, Forfait, ServiceNom, BasePicto}