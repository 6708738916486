import React from "react";

function Icon({width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 128 175"
    >
      <g
        fill="#fff"
        stroke="#a50000"
        strokeDasharray="none"
        strokeLinejoin="round"
        strokeMiterlimit="4"
      >
        <path
          fillOpacity="1"
          strokeLinecap="butt"
          strokeOpacity="1"
          strokeWidth="2.379"
          d="M24.001 51.972l12.474-13.23 6.614 6.237c3.99-2.45 7.903-4.99 13.607-5.48l.567-10.017h17.954v9.26c4.723 1.12 9.435 2.277 13.418 5.86l6.615-5.67 13.04 12.473-5.67 6.992c2.206 4.122 4.6 7.947 5.103 14.741l9.26.378.568 19.844-10.206.19c-.446 5.476-2.592 9.82-5.291 13.795l6.236 7.37-12.284 13.986-6.804-6.804c-4.095 3.055-8.638 4.541-13.229 5.859l-.567 10.016H57.074v-9.827c-4.472-1.205-8.945-2.956-13.418-5.67l-6.992 6.426-13.418-13.418 6.992-7.182c-2.986-4.429-5.077-9.304-6.048-14.74l-9.827.188.378-19.655h9.45c1.228-4.889 2.593-9.793 6.047-14.93z"
          opacity="1"
        ></path>
        <ellipse
          cx="65.957"
          cy="83.863"
          strokeLinecap="round"
          strokeWidth="2.379"
          opacity="1"
          paintOrder="fill markers stroke"
          rx="28.636"
          ry="30.384"
        ></ellipse>
        <path
          strokeLinecap="round"
          strokeWidth="2.039"
          d="M102.448-1.145h10.204v28.279l-19.543-.525.842-10.958 8.064.073z"
          opacity="1"
          paintOrder="fill markers stroke"
          transform="matrix(.72632 .68735 -.68118 .73212 0 0)"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
