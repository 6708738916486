import React, { useEffect, useState} from "react";
import useDocumentLoad from "../../hooks/useDocumentLoad"
import { useHistory } from 'react-router-dom';


export default function(){

    const history = useHistory();
    

    const [timer, setTimer] = useState()
    const [countDown, setCountDown]  = useState(5)


    useEffect(() => {

        window.scrollTo(0,0)

        setTimer(
            setInterval(() => {

                setCountDown((oldState) => {
                    if(oldState <= 0){
                        clearInterval(timer)
                        history.push("/home")
                    }else{
                        return oldState -1
                    }
                })


        }, 1000) )

    }, [])



    return(
        <div
        style={{
            minHeight: '600px',
            textAlign: 'center',
            fontSize: '2em',
            display: 'flex', 
            alignItems: 'center', 
            flexDirection: 'column',
            justifyContent: 'center'
        }}
        >

            <h2 style={{fontSize: '1.4em'}}>Merci de votre intérêt!</h2>

            <p>Nous vous répondrons sous peu.</p>

            <p style={{fontSize: '0.5em'}}>Vous serez redirigé automatiquement dans... {countDown}</p>
        
        </div>

    )
}