import React from "react";

function Icon({width, height, style}) {
  return (
    <svg 
    style={style}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg" viewBox="2.167 2.687 98.288 84.367">
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H206V213H0z"></path>
        </clipPath>
      </defs>
      <g
        clipPath="url(#clip0)"
        transform="matrix(.50068 0 0 .50683 -3.845 -6.492)"
      >
        <path
          fill="#fff"
          stroke="#A50000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.379"
          d="M108.278 71.806c8.869 0 16.058-10.285 16.058-22.973 0-12.687-7.189-22.972-16.058-22.972-8.868 0-16.058 10.285-16.058 22.972 0 12.688 7.19 22.973 16.058 22.973z"
        ></path>
        <path
          fill="#fff"
          stroke="#A50000"
          strokeWidth="2.379"
          d="M62.656 104.926l16.172 9.238s-7.038 23.737-12.492 28.69c-5.025 4.563-24.782 17.194-29.266 16.941-5.45-.309-12.418-4.078-7.672-7.339 7.628-5.241 20.23-17.095 25.779-23.735l7.479-23.795zM151.954 107.679l-16.172 7.838s6.868 24.283 12.707 28.69c4.809 3.63 24.137 15.328 28.621 15.074 5.45-.308 12.633-1.744 7.886-5.006-7.627-5.24-20.23-17.094-25.778-23.735l-7.264-22.861z"
        ></path>
        <path
          fill="#fff"
          stroke="#A50000"
          strokeLinecap="round"
          strokeWidth="2.379"
          d="M62.655 104.926s4.121-19.464 11.329-23.733c9.503-5.629 33.765-10.032 33.765-10.032s26.508 5.466 33.336 11.198c6.787 5.698 10.869 25.32 10.869 25.32l-16.172 7.838-5.451-13.792.431 42.229-47.316.467v-42.93l-4.618 12.673-16.173-9.238z"
        ></path>
        <path
          fill="#fff"
          stroke="#A50000"
          strokeWidth="2.379"
          d="M83.447 144.421l-45.81 15.399c-4.967 7.825-3.91 14.882.86 21.464l136.354.7c8.648-8.436 6.186-16.128.215-23.331l-44.304-14.699-47.316.467z"
        ></path>
        <path
          fill="#fff"
          d="M67.962 165.186l44.089 4.899c2.704 3.343 6.649 6.108 5.591 11.199.746-4.174-1.779-7.642-5.591-11.199l39.572-2.566"
        ></path>
        <path
          stroke="#A50000"
          strokeWidth="2.379"
          d="M67.962 165.186l44.089 4.899c2.704 3.343 6.649 6.108 5.591 11.199.746-4.174-1.779-7.642-5.591-11.199l39.572-2.566"
        ></path>
        <path
          fill="#fff"
          stroke="#A50000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.379"
          d="M191.008 181.475H23.724v.33h167.284v-.33z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
