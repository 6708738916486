
import indexBanneerImage from "../../images/room.jpg"
import aboutImage from "../../images/office.jpg"
import Building from "../../images/building.jsx"



//Property management services
import ServiceAccountingSVG from "../../images/serivce_accounting"
import ServiceCommunicationSVG from "../../images/serivce_communication"
import ServiceMaintenanceSVG from "../../images/serivce_maintenance"
import ServiceManagementSVG from "../../images/serivce_management"
import ServiceRentalSVG from "../../images/service_rental"
import WaveDown from "../../images/waveDown"
import WaveUp from "../../images/waveUp"

import Expertise  from "../../images/ExpertiseExpertise.jsx"
import ExpertiseTime from "../../images/ExpertiseTime.jsx"
import ExpertiseTranquility from "../../images/ExpertiseTranquility.jsx"

import Wavy from "../../images/wavy.jsx"



import React, { Component , useState } from "react"

import "../../fonts/index.css"


import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';


import useWindowSize from "../../hooks/useWindowSize"
import Button from "../global/Button"

import {  Slide, Fade, AttentionSeeker } from "react-awesome-reveal";
import { Children } from "react"

import { Parallax, Background } from 'react-parallax';

const SIDE = {
    "RIGHT" : 0,
    "LEFT" : 1}



function Card({ side, children}){

    const alignItSelf = (side == SIDE.RIGHT ?  "flex-start" : "flex-end")
    const left = (side == SIDE.RIGHT ? "-5vh" : "5vh")
    const justifySelf = (side == SIDE.RIGHT ?  "flex-start" : "flex-end")

    return (
        <div
        className="card-avantages"
        style={{
        width : '50%', 
        position : 'relative', 
        display: 'flex',
        justifyContent: 'center',
        margin: '70px',
        alignSelf: alignItSelf,
        boxShadow: "rgba(0, 0, 0, 0.4) 0px 30px 90px"
 
}}>

        <div style={{     
            backgroundColor : '#a50000',
            top: '-5vh',
            width: '100%',
            height: '100%',
            zIndex: '-9999',
            left: left,
            position: 'absolute',
            borderRadius: '1%'
            }}>

            </div>


                <div style={{
                    backgroundColor: 'white', 
                    borderRadius: '1%',
                    width: '100%', 
                    display: 'flex', 
                    justifyContent: justifySelf,
                    alignItems: 'flex-end',
                    boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                    padding: '20px'

                }}>
                    {children}

                </div>

            </div>
    )
}


export default function Home(){

    const size = useWindowSize()

    return (

        <ParallaxProvider>
    <div >

        <div 
        className="home-banner"
        style={{ margin: '40px'}}>
        {/* Real Estate Management Company */}


        <ParallaxBanner
           disabled={size.width > 950 ? false: true}
        
            layers={[
            {
            image:  indexBanneerImage,
            amount: 0.8,
            }      
        ]}
        style={{
            height: '550px', 
            display: 'flex', 
            justifyContent: 'flex-start', 
            padding: '20px',

            alignItems: 'center'


        }}
        >
            <div style={{
                position: 'absolute',
                color: 'white', 
                maxWidth: '800px', 
                justifyContent: 'left',
                fontFamily: 'Arial',
                textShadow: "4px -1px 8px rgba(56, 56, 56, 1)",
                padding: '20px'

                
                
                }}>
            <h2 style={{  
                fontSize: '5vh', 
            fontWeight: 'bold',  
            color: "white",


            }}>
                UNE COMPAGNIE DE GESTION PAS COMME LES AUTRES!
            </h2>
            <p style={{fontSize: '1.2em'}}>La façon de maximiser le potentiel de vos propriétés!</p>
            </div>
    </ParallaxBanner>

    </div>



        {/* Real Estate Management Company */}
        <section id="my-aparts-inc" 
        style={{ 
            display: 'flex',
            marginTop: '50px',
         alignItems: 'center', 
        marginBottom: '50px', 
        flexWrap: 'wrap', 

        margin: '40px'
        }}> 
        

        <ParallaxBanner
           disabled={size.width > 950 ? false: true}

            className="banner-image"
            layers={[
            {
            image:  aboutImage,
            amount: 0.8,
            }
      
        ]}
        style={{
            height: '500px', 
            display: 'flex', 
            alignItems: 'center',
            flex: 1,
            minWidth: '200px',
            maxWidth: '1000px'




        }}
        >
    </ParallaxBanner>

       
    <div 
    className="description"
    style={{

    padding: '10px', 
    textAlign: 'left', 
    maxWidth: '600px', 
    fontSize: '120%', 
    justifyContent: 'center', 
    fontFamily: 'Arial',
 
    display: 'flex', 
    justifyContent: 'flex-start', 
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: '5%',
    paddingTop: '20px'

    }}>
        <h2

        >À propos de MesApparts</h2>
                <p
                style={{
                    textAlign: 'justify'
                }}
                >
                Découvrez notre société de gestion immobilière, de son histoire à sa croissance en passant par son implication dans l'élaboration de normes et de meilleures pratiques pour l'ensemble du secteur immobilier et des sociétés de gestion immobilière.
                </p>

                <Button.Button
                style={{
                    
                    padding: '10px',
                    width: '50%'
                
                }}
                href="/apropos"
                text="Lire la suite"

                />

         
        </div>
               
        </section>


   

        <section id="service-proprietaire">
        

                    <WaveUp
                    />

                    


                    <div
                    style={{
                        position: 'relative', 
                        background: "linear-gradient(0deg, rgba(165,0,0,1) 0%, rgba(149,3,3,1) 50%, rgba(165,0,0,1) 100%)",
                        color: 'white',
                        
                        width: '100%'
                    }}
                    >
                        <h2 
                        
                        style={{
                            textAlign: 'center',
                            fontSize: '2.5em',
                           
                            marginBottom: '5vh',
                            marginLeft: 'auto',
                            marginRight: 'auto',

                    
                    
                    }}
                        
                        
                        >
                            
                            NOS SERVICES
                        <span
                        style={{
                            marginLeft: 'auto', 
                            marginRight: 'auto',
                            textAlign: 'center',
                            marginTop: '20px',
                            width: '100px',
                            borderTop: '3px solid white',
                            display: 'block'
                        }}
                        ></span>
                        
                        </h2>
                   
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-around', 
                        flexWrap: 'wrap',
                        fontFamily: 'Arial'

                    }}>
                        <AttentionSeeker
                        className="bouncy-img"
                        cascade
                        duration={1000}
                        >
                        <div  style={{textAlign: 'center'}}>
                            <ServiceAccountingSVG height='200px'/>
                            <h3>COMPTABILITÉ</h3>
                        </div>

                        <div style={{textAlign: 'center', width: '100%'}}>
                            <ServiceCommunicationSVG height='200px'/>
                            <h3>COMMUNICATION</h3>
                        </div>

                        <div  style={{textAlign: 'center'}}>
                            <ServiceMaintenanceSVG height='200px'/>
                            <h3>MAINTENANCE</h3>
                        </div>

                        <div  style={{textAlign: 'center'}}>
                            <ServiceManagementSVG height='200px'/>
                            <h3>GESTION</h3>
                        </div>

                        <div className="bouncy-img" style={{textAlign: 'center'}}>
                            <ServiceRentalSVG height='200px'/>
                            <h3>LOYERS</h3>
                        </div>

                        </AttentionSeeker>

                    </div>
              
                </div>
                <WaveDown/>


                

               <div id="expertise"
         
               >

<Fade 
   
     style= {{
        textAlign: 'center',
        display: 'flex', 
        justifyContent: 'center',
        flexDirection :'column',
        marginLeft: '10%',
        marginRight: '10%',

    }}
    delay={20}
    triggerOnce
cascade>
                <h2
                   style={{
                    textAlign: 'center',
                    fontSize: '2.5em',
                   
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '10vh'
   
                   }}
                   >NOS AVANTAGES
                   
                   <span
                        style={{
                            marginLeft: 'auto', 
                            marginRight: 'auto',
                            textAlign: 'center',
                            marginTop: '20px',
                            width: '30%',
                            borderTop: '3px solid #a50000',
                            display: 'block'
                        }}
                        ></span>
                   </h2>


                   <Card
                   side={SIDE.RIGHT}
                   >
                       <div
>
                       <Expertise  style={{padding: '10px'}} height={200} width={200}/>
                       </div>
                       
                       <p
                        style={{
                            fontSize: '1.2em',
                            alignSelf: 'center',
                            textAlign: 'left',
                            minWidth: '100px',
                            width: '60%',
                            justifySelf: 'center',

                         }}
                       >
                         
                           Bénificiez de notre <span style={{color: '#a50000', fontSize: '1.2em'}}>EXPERTISE</span> en automatisation et en <span>administration</span>!

                           
                           </p>

                   </Card>


                   <Card
                   side={SIDE.LEFT}
                   >
                                             <p
                        style={{
                            fontSize: '1.2em',
                            alignSelf: 'center',
                            width: '60%',
                            textAlign: 'center',

                            justifySelf: 'center'
                         }}
                       >
                           Facilitez votre gestion du <span style={{color: '#a50000',fontSize: '1.2em'}}>TEMPS</span> et économisez de l'<span style={{color: '#a50000',fontSize: '1.2em'}}>ARGENT</span>!
</p>
                       <ExpertiseTime  style={{padding: '10px'}} height={200} width={200}/>

                   </Card>

                   <Card
                   side={SIDE.RIGHT}
                   >
                   <ExpertiseTranquility style={{padding: '10px'}} width="200px" height='200px'/>

                   <p
                        style={{
                            fontSize: '1.2em',
                            alignSelf: 'center',
                            width: '60%',
                            textAlign: 'center',
                            justifySelf: 'center'
                         }}
                       > Opérez en toute <span style={{color: '#a50000',fontSize: '1.2em'}}>TRANQUILITÉ</span>  et évitez le stress inutile</p>


                   </Card>



      
                   </Fade>




   

    

         

               </div> 

        </section>
        
        <div
        style={{
           width: '100%',
           height: '100px', 
           backgroundColor : '#a50000'
        }}
        >


        </div>

        </div>
        </ParallaxProvider>
        )
}