import * as React from "react"

const SvgComponent = ({strokeColor="white", 
backgroundColor="#A50000",
width="70%", 
height="70%"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="97mm"
    height="124mm"
    viewBox="0 0 97 124"
    style={{
      height: height,
      width: width
    }}
  >
    <path
      style={{
        fill: backgroundColor,
        stroke: strokeColor,
        strokeWidth: 2.765,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="M44.842 2.02c-11.908-.06-17.482 5.61-17.15 31.135-6.702 5.822-3.759 12.207 2.267 17.056 4.623 12.05 5.35 21.476-12.237 25.136-10.42 2.169-16.149 14.222-16.205 21.78l-.19 25.136h90.904l3.59-43.656-8.929-.567c-.018-5.45-3.121-5.71-6.756-1.512l-15.592-3.307c-7.785-3.16-7.76-14.878-3.118-23.151 5.517-4.739 7.596-11.637.992-17.151-1.286-17.765 2.245-30.8-17.576-30.9Z"
    />
    <path
      style={{
        fill: backgroundColor,
        stroke: strokeColor,
        strokeWidth: 2.765,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="m38.039 3.106-.567 22.442c-2.86.821-7.305 3.03-9.591 5.103 0 0-.748 13.794 1.937 19.891 3.312 7.522 8.319 19.225 16.489 18.332 1.756-.192 6.297 1.227 15.119-18.804.43-5.537 1.139-10.575.992-17.151l-.094 5.67c-14.127-13.649-25.11-8.406-34.49.803 13.325-13.476 24.67-10.243 34.49-.803l-.048-8.741c-12.154-6.948-18.488-5.729-24.852-4.3 5.021-1.568 10.32-1.643 15.97.19l-.662-22.68c-3.43-1.004-9.55-1.681-14.693.048zM11.486 78.134l8.74 26.27.15 17.815 43.79-.003L86.892 78.04c-.183-5.975-3.179-5.188-6.756-1.512l-20.411 41.389 4.441 4.3 3.78-7.23.472 6.993-.472-6.993 5.197-10.205s7.95 7.885 9.213 7.418c1.45-.535 4.238-4.561 3.638-6.095l-1.7-4.347 3.874.378 3.874-6.803-1.228-3.024 3.968-1.418.378-5.008-8.835-6.709-22.159 43.042-4.441-4.3 2.362-4.44-36.002-.095 35.908.095 3.921-8.08-.33-5.197-39.499.142-8.363-24.947 8.269 24.852 39.593-.047-.756-7.89c-2.264.836-4.585-2.672-4.725-8.788-17.977 13.744-31.308 1.417-33.97-10.82 2.635 12.16 15.982 24.638 33.97 10.82l3.402-5.339 1.323 13.938 7.465-.095 6.803-13.418-15.591-.425c1.139-1.73.71-2.999 1.04-4.96l15.59 3.306"
    />
  </svg>
)

export default SvgComponent
