import React, { useState, } from "react"

import { H2 } from '../global/Title'
import { SERVICES, BasePicto } from "../../components/Service"
import header from "../../images/services/header2.jpg"

import Header from "../global/Header"
import { ParallaxProvider } from 'react-scroll-parallax';
import useWindowSize from "../../hooks/useWindowSize"



import { useHistory } from "react-router-dom";

function Section({ id, style, image, title, imgRight, children }) {

    const size = useWindowSize()

    const baseStyle = {

        title: {
            color: '#a50000'
        },
        section: {
            ...style,
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            marginLeft: 'auto',
            marginRight: 'auto',
            alignItems: 'center'
        },
        img: {
            borderRadius: '20px', padding: '10px', maxWidth: '500px',
            minWidth: '300px'
        }

    }

    if (imgRight === true && size.width > 1400) {
        baseStyle.img.order = 1
        baseStyle.img.marginLeft = '75px'

    } else if (size.width > 1400) {

        baseStyle.img.marginRight = '75px'
    }

    if (size.width < 1400) {
        baseStyle.img.order = 1
    }




    return (<section id={id} style={baseStyle.section}>


        <img style={baseStyle.img} height='300px' src={image} />

        <div style={{
            width: '40%',

            minWidth: '300px', textAlign: 'justify', margin: '50px'
        }}>
            {title}
            {children}
        </div>




    </section>)
}

function ServiceModal({ modal, handleCloseModal, picto }) {

    const Picto = picto;


    return (
        <div className={`custom-modal ${modal.open ? "enable" : ""}`}>

            <div className={`custom-content ${modal.open ? "enable" : ""}`}>
                <p onClick={handleCloseModal}
                    className="custom-modal-close">X</p>

                <div>
                    <h2>{modal.content.title.simple} <span className="subtitle">{modal.content.title.emphasis}</span> </h2>

                    <div className="desc">
                        <p>Le service comprend les tâches suivantes</p>
                        <ul>
                            {
                                modal.content.tasks.map(elem => <li>{elem}</li>)
                            }
                        </ul>
                    </div>
                </div>



            </div>

        </div>)
}



export default function Services() {

    const history = useHistory()


    const [state, setState] = useState({

        modal: {
            open: false,
            content: {
                title: {
                    simple: "",
                    emphasis: ""
                },
                picto: null,
                tasks: []
            }
        },

        services: []
    })
    const getServiceRows = (start, end) => {

        return Object.entries(SERVICES).slice(start, end).map((keyValue) => {

            const [key, value] = keyValue

            const Picto = value.picto
            const model = value.model
            const Header = value.header

            return (<BasePicto
                {...model}
                handleOpenModal={handleOpenModal}
                header={Header}
                picto={Picto}
            />
            )

        })

    }
    const handleOpenModal = ({
        title,
        tasks,
        picto
    }) => {
        
        setState(oldState => ({
            ...oldState,
            modal: {
                open: true,
                content: {
                    title: title,
                    tasks: tasks,
                    picto: picto
                }
            }
        }))
    }
    const handleCloseModal = () => {

        setState(oldState => {

            let newState = { ...oldState };

            newState.modal.open = false;

            return newState
        })


    }
    const updateServices = (services) => {

        setState(oldState => {

            let newState = { ...oldState };
            newState.services = services
            return newState
        })


    }

    return (
        <ParallaxProvider>

            <div>

                <ServiceModal
                    modal={state.modal}
                    picto={state.modal.content.picto}
                    handleCloseModal={handleCloseModal}
                />

                <Header
                    image={header}
                    smallImage={""}
                    style={{ marginBottom: '100' }}
                    title="NOS SERVICES"
                />



                <section className="services-infos">

                    <H2
                        color='black'
                        borderColor="#a50000"
                    >NOS <span className="red">SERVICES</span></H2>

                    <p> Notre société de gestion immobilière offre un éventail complet de services pour aider les
                        propriétaires et les copropriétaires avec la gestion de leurs immeubles, appartements (locataires)
                        et condos dans la Ville de Québec (Beauport, Charlesbourg, Sainte-Foy, l’Ancienne-Lorette, Québec centre-ville,
                        Limoilou, la Haute-Saint-Charles, Cap-Rouge, ...) et les alentours (Lévis, Saint-Augustin, St-Romuald, ...).
                    </p>
                    <p> C’est tout à votre avantage de faire appel à un gestionnaire d’une compagnie immobilière. De plus,
                        nos services permettent que vos appartements soient remarqués et loués dans les meilleurs délais,
                        la tranquillité d’esprit en répondant rapidement aux demandes des locataires, de sauver du temps,
                        d’éviter les soucis, d’assurer la rentabilités des propriétés et de mieux vous préparer à ces événements
                        indésirables qui font augmenter les coûts.
                    </p>

                </section>

                <section className="services">
                    <div className="row-services">
                        {
                            getServiceRows(0, 4)
                        }
                    </div>
                    <div className="row-services">
                        {
                            getServiceRows(5, 9)
                        }
                    </div>

                    <div className="row-services">
                        {
                            getServiceRows(10, 14)
                        }
                    </div>
                </section>


                <div
                    style={{
                        width: '100%',
                        height: '100px',
                        backgroundColor: '#a50000'
                    }}
                ></div>

            </div>

        </ParallaxProvider>
    )
}