import * as React from "react"

const SvgComponent = ({
            strokeColor="white", 
            backgroundColor="#A50000",
            width="70%", 
            height="70%"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="102mm"
    height="107mm"
    viewBox="0 0 102 107"
    style={{
      height: height,
      width: width
    }}
  >
    <ellipse
      style={{
        opacity: 1,
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 3.9,
        strokeLinecap: "round",
        strokeMiterlimit: 4,
        strokeDasharray: backgroundColor,
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      cx={12.424}
      cy={90.608}
      rx={10.405}
      ry={15.602}
    />
    <ellipse
      style={{
        opacity: 1,
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 3.9,
        strokeLinecap: "round",
        strokeMiterlimit: 4,
        strokeDasharray: backgroundColor,
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      cx={65.53}
      cy={83.332}
      rx={7.918}
      ry={11.415}
    />
    <ellipse
      style={{
        opacity: 1,
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 3.9,
        strokeLinecap: "round",
        strokeMiterlimit: 4,
        strokeDasharray: backgroundColor,
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      cx={65.341}
      cy={83.048}
      rx={15.308}
      ry={22.584}
    />
    <path
      style={{
        fill: backgroundColor,
        stroke: strokeColor,
        strokeWidth: 3.9,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: backgroundColor,
        strokeOpacity: 1,
      }}
      d="m2.786 106.01 98.084.378zM19.039 77.85l6.692-9.981 28.081-.034-8.079-.19.142-8.126h3.213v-9.827l-21.356-.19.189 10.206 17.954-.189-14.93.189.055 8.106M67.986 60.275s16.72-57.784 18.521-57.83l14.552-.378v7.56H89.531l-13.985 55.75"
    />
    <path
      style={{
        fill: backgroundColor,
        stroke: strokeColor,
        strokeWidth: 3.9,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: backgroundColor,
        strokeOpacity: 1,
      }}
      d="M80.649 45.723c7.017-.093 12.355 4.382 13.985 11.906.598 2.76.914 23.695-.48 27.673-3.903 11.144-9.533 8.695-15.584 8.99M34.158 77.85l7.937-.188M34.158 85.977l7.937-.189M21.495 96.56h31.372"
    />
  </svg>
)

export default SvgComponent
