import React from "react";


function  Icon() {

  return (  <svg fill='white' width="40" height="40" viewBox="0 0 100 80">
  <path d="M0 0H100V20H0z"></path>
  <path d="M0 30H100V50H0z"></path>
  <path d="M0 60H100V80H0z"></path>
</svg>)
}


function Hamburger({visible, handleClick}) {



  return ( visible ? <div onClick={handleClick} className="hamburger" style={{cursor: "pointer"}}><Icon/></div> : <div></div>);
}

export default Hamburger;
