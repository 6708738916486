import React from "react";

function Icon({width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 128 175"
      
    >
      <g
        stroke="#a50000"
        strokeDasharray="none"
        strokeMiterlimit="1.714"
        strokeOpacity="1"
      >
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.379"
          d="M61.406 11.865c-31.99-.142-36.598 39.644-34.769 40.265 1.753-42.1 41.792-50.388 60.66-27.687-5.253-6.825-13.202-12.282-25.13-12.567-.256-.007-.51-.01-.761-.011zm25.89 12.578c5.497 7.142 8.04 15.778 9.09 21.569-1.277-8.936-4.576-16.14-9.09-21.569zm9.09 21.569c.297 2.073.487 4.237.555 6.496.186-.07.141-2.66-.555-6.496zM61.69 22.682C33.56 25.222 32.747 49.153 34.743 70.68l-.008-17.696c-3.593-1.426-10.768-.324-11.939.837-2.049 7.84-1.503 9.699-.612 16.202 6.058 2.406 6.503 1.604 12.561.803l-.002-.146c2.298 14.503 9.786 24.11 26.194 24.745 18.402.712 26.448-11.674 28.04-29.2 1.81-24.162-4.234-41.864-27.288-43.544zm27.287 38.631c.105 1.1.256 7.206.447 8.601 6.058 1.07 5.969.535 12.027-.267.624-9.265 1.515-6.236 0-15.502-7.212-1.275-5.761-1.89-10.958-.801 0 0-1.248 6.473-1.516 7.97zm-15.744 39.982l-.764-3.484c.456 4.695-.313 13.078-10.194 13.908-13.402 1.125-10.423-17.105-10.423-17.105-.111-.189-.205-.28-.285-.292-.766-.112-.165 7.275-.165 7.275-30.208 9.749-45.528 11.725-39.908 58.23l20.58.134 57.266-.034 23.716-.1c-.462-20.73.99-32.509-6.418-41.456-8.29-8.055-10.631-9.853-33.405-17.076zm-.764-3.484c-.258-2.655-.907-4.136-.525-2.394z"
          opacity="1"
          paintOrder="fill markers stroke"
        fill='white'

        ></path>
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.779"
          d="M96.374 71.029s1.815 8.68-1.512 10.961c-5.631 3.861-30.994 2.646-30.994 2.646s32.506 2.837 32.506-5.292z"
        fill='white'

        ></path>
        <rect
          width="0.802"
          height="30.201"
          x="31.805"
          y="129.893"
          fill="#000"
          fillOpacity="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.779"
          opacity="1"
          paintOrder="fill markers stroke"
          ry="0.033"
        fill='white'

        ></rect>
        <rect
          width="0.802"
          height="30.201"
          x="90.738"
          y="130.027"
          fill="#000"
          fillOpacity="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.779"
          paintOrder="fill markers stroke"
        fill='white'

          ry="0.033"
        ></rect>
        <rect
          width="3.207"
          height="3.475"
          x="46.772"
          y="64.679"
          fill="#000"
          fillOpacity="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.779"
          opacity="1"
          paintOrder="fill markers stroke"
          ry="0.033"
          
        ></rect>
        <rect
          width="3.207"
          height="3.475"
          x="73.766"
          y="64.813"
          fill="#000"
          fillOpacity="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.779"
          paintOrder="fill markers stroke"
          
          ry="0.033"
          
        ></rect>
        <path
          fill="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeWidth="1.779"
        fill='white'

          d="M34.735 52.985C47.62 54.219 63.973 57.19 72.43 42.229c4.946 8.732 12.67 14.677 18.062 11.115-4.943 3.12-10.98-.693-18.062-11.115-9.59 15.978-24.016 11.813-37.695 10.756z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
