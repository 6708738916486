import Regx from "../global/Regx"



function baseModel(name, errorMsg, isValid, required, value){
    this.name = name
    this.errorMsg = errorMsg
    this.isValid = isValid !== undefined ? isValid : (value) => {return true}
    this.required = required !== undefined ?  required : false
    this.value = value !== undefined ? value : ""
    this.msg = ""
}

function modelToData(model){

    let data = {}

    Object.entries(model).forEach((elem) => {

        data[elem[0]]  = elem[1].value
    })

    return data
}

function ContactsInfoModel(){
    const self = this;
    this.first_name = new baseModel("first_name", "Ce champ est invalide", (value)=>{ return new RegExp(Regx.ALPHA).test(value.trim())}, true)
    this.last_name = new baseModel("last_name", "Ce champ est invalide", (value)=>{ return new RegExp(Regx.ALPHA).test(value.trim())}, true)
    this.email = new baseModel("email", "Ce champ est invalide", (value)=>{ return new RegExp(Regx.EMAIL).test(value.trim())}, true ) 
    this.phone_number = new baseModel("phone_number", "Ce champ est ", (value)=>{ return new RegExp(Regx.PHONE_NUMBER).test(value.trim().replace(/\D/g, ''))}, true )
}

function AutresBesoins(){
    const self = this;
    this.autres_besoins= new baseModel("autres_besoins", "")
    this.stats_heard_about_us_other= new baseModel("stats_heard_about_us_other", "Ce champ est invalide", (value) => ((
        self.stats_heard_about_us.value === "other" &&  value.trim() !== "") || 
        (self.stats_heard_about_us.value !== "other" )), false, "")
  
}

function  model() {

    const self = this;
                   
    
    this.nb_immeubles = new baseModel("nb_immeubles", "", undefined, false, 0) 
    
    this.nb_unites_total = new baseModel("nb_unites_total", "", undefined, false, 0)
    
    this.categorie_biens_immobiliers= new baseModel("categorie_biens_immobiliers", "", undefined, false, 'Condominium') 
    
    this.revenus_brutes_par_an= new baseModel("revenus_brutes_par_an", "", undefined, false, 0)
    
    this.delai_de_gestion = new baseModel("delai_de_gestion", "", undefined, false, "3-months")

};




export { model, modelToData, ContactsInfoModel, AutresBesoins}