function H2({style, children, color, borderColor}){

    return (
        <h2 
                        
        style={{
            ...style,
            color: color === undefined ? "white" : color,
            textAlign: 'center',
            fontSize: '2em',
            marginBottom: '5vh',
            marginLeft: 'auto',
            marginRight: 'auto',

    
    
    }}
        
        
        >
            
           {children}
        <span
        style={{
            marginLeft: 'auto', 
            marginRight: 'auto',
            textAlign: 'center',
            marginTop: '20px',
            width: '100px',
            borderTop: '3px solid ' + (borderColor === undefined ? "white" : borderColor),
            display: 'block'
        }}
        ></span>
        
        </h2>
    )
}

export {H2}