import React from "react";

function Button({style, href, text ,onClick,note}){

    return(   <React.Fragment>  <a 
        className='btn-plus btn-more'
        href={href}
        onClick={onClick}
        style={{ 

        borderRadius: '3px',
        textAlign: 'center', 
        fontSize: '0.8em',
        textDecoration: 'none',
        color: 'white',
        ...style,

        }}>{text}</a>
        {note}
        </React.Fragment>   )

}


function Submit({style, className}){

    return(    <button
        type="submit"
        className={'btn-plus btn-more '+className }
        style={{
            ...style,
                color: 'white',
                paddingTop: '5px',
                paddingBottom: '5px',
                marginTop: '10px'
                
            }}
                        value="Envoyer"
                        type="submit">Envoyer</button> )

}


export  default Button = {
    Button, 
    Submit
} 