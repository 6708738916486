import React from "react";

function Icon({width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 128 175"
    >
      <g strokeDasharray="none" strokeMiterlimit="1.714">
        <rect
          width="82.021"
          height="116.606"
          x="23.435"
          y="31.939"
          fill="#fff"
          fillOpacity="1"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.379"
          opacity="1"
          paintOrder="fill markers stroke"
          ry="2.112"
        ></rect>
        <rect
          width="19.466"
          height="0.378"
          x="77.863"
          y="68.225"
          fill="#000"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          opacity="1"
          paintOrder="fill markers stroke"
          rx="0"
          ry="0"
        ></rect>
        <rect
          width="19.466"
          height="0.378"
          x="77.646"
          y="76.679"
          fill="#000"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          opacity="1"
          paintOrder="fill markers stroke"
          ry="0"
        ></rect>
        <rect
          width="19.466"
          height="0.378"
          x="77.769"
          y="85.234"
          fill="#000"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          opacity="1"
          paintOrder="fill markers stroke"
          ry="0"
        ></rect>
        <rect
          width="19.466"
          height="0.378"
          x="77.801"
          y="93.866"
          fill="#000"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          opacity="1"
          paintOrder="fill markers stroke"
          ry="0"
        ></rect>
        <rect
          width="1.768"
          height="1.596"
          x="69.677"
          y="67.658"
          fill="#000"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          opacity="1"
          paintOrder="fill markers stroke"
          rx="0"
          ry="0.798"
        ></rect>
        <rect
          width="1.768"
          height="1.596"
          x="69.709"
          y="76.176"
          fill="#000"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          opacity="1"
          paintOrder="fill markers stroke"
          rx="0"
          ry="0"
        ></rect>
        <rect
          width="1.768"
          height="1.596"
          x="69.625"
          y="84.695"
          fill="#000"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          opacity="1"
          paintOrder="fill markers stroke"
          rx="0"
          ry="0"
        ></rect>
        <rect
          width="1.768"
          height="1.596"
          x="69.709"
          y="93.264"
          fill="#000"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          opacity="1"
          paintOrder="fill markers stroke"
          rx="0"
          ry="0"
        ></rect>
        <ellipse
          cx="52.066"
          cy="120.385"
          fill="#fff"
          fillOpacity="1"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          opacity="1"
          paintOrder="fill markers stroke"
          rx="16.634"
          ry="19.374"
        ></ellipse>
        <text
          xmlSpace="preserve"
          style={{
            lineHeight: "1.25",
            InkscapeFontSpecification: "Constantia",
          }}
          x="38.718"
          y="143.152"
          fill="#a50000"
          fillOpacity="1"
          stroke="none"
          strokeOpacity="1"
          strokeWidth="0.979"
          fontFamily="Constantia"
          fontSize="34.974"
          transform="scale(1.09128 .91636)"
        >
          <tspan
            x="38.718"
            y="143.152"
            style={{ InkscapeFontSpecification: "Cambria" }}
            fill="#a50000"
            fillOpacity="1"
            stroke="none"
            strokeDasharray="none"
            strokeMiterlimit="1.714"
            strokeOpacity="1"
            strokeWidth="0.979"
            fontFamily="Cambria"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
          >
            $
          </tspan>
        </text>
        <path
          fill="#fff"
          fillOpacity="1"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          d="M118.739 90.941c2.557 2.425.662 4.65-.08 5.733l-32.172 37.74c-.665.78-9.423 5.997-10.42 5.82l-.31-.055c-.996-.176 4.156-10.259 4.821-11.039l32.172-37.739c.665-.78 3.536-2.786 5.989-.46z"
          opacity="1"
          paintOrder="fill markers stroke"
        ></path>
        <rect
          width="7.866"
          height="5.335"
          x="145.045"
          y="-2.96"
          fill="#fff"
          fillOpacity="1"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          opacity="1"
          paintOrder="fill markers stroke"
          ry="0"
          transform="matrix(.74609 .66585 -.64874 .761 0 0)"
        ></rect>
        <rect
          width="49.515"
          height="70.115"
          x="10.394"
          y="22.679"
          fill="#fff"
          fillOpacity="1"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          opacity="1"
          paintOrder="fill markers stroke"
          ry="6.426"
        ></rect>
        <rect
          width="37.231"
          height="10.772"
          x="16.442"
          y="29.104"
          fill="none"
          fillOpacity="1"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          opacity="1"
          paintOrder="fill markers stroke"
          ry="0"
        ></rect>
        <rect
          width="7.56"
          height="9.116"
          x="18.332"
          y="46.447"
          fill="none"
          fillOpacity="1"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          opacity="1"
          paintOrder="fill markers stroke"
          ry="1.982"
        ></rect>
        <rect
          width="7.56"
          height="9.116"
          x="31.372"
          y="46.469"
          fill="none"
          fillOpacity="1"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          paintOrder="fill markers stroke"
          ry="1.982"
        ></rect>
        <rect
          width="7.56"
          height="9.116"
          x="44.601"
          y="46.658"
          fill="none"
          fillOpacity="1"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          paintOrder="fill markers stroke"
          ry="1.982"
        ></rect>
        <rect
          width="7.56"
          height="9.116"
          x="18.521"
          y="61.399"
          fill="none"
          fillOpacity="1"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          paintOrder="fill markers stroke"
          ry="1.982"
        ></rect>
        <rect
          width="7.56"
          height="9.116"
          x="31.372"
          y="61.777"
          fill="none"
          fillOpacity="1"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          paintOrder="fill markers stroke"
          ry="1.982"
        ></rect>
        <rect
          width="7.56"
          height="9.116"
          x="44.601"
          y="61.588"
          fill="none"
          fillOpacity="1"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          paintOrder="fill markers stroke"
          ry="1.982"
        ></rect>
        <rect
          width="7.56"
          height="9.116"
          x="18.71"
          y="76.896"
          fill="none"
          fillOpacity="1"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          paintOrder="fill markers stroke"
          ry="1.982"
        ></rect>
        <rect
          width="7.56"
          height="9.116"
          x="31.372"
          y="76.707"
          fill="none"
          fillOpacity="1"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          paintOrder="fill markers stroke"
          ry="1.982"
        ></rect>
        <rect
          width="7.56"
          height="9.116"
          x="44.223"
          y="76.896"
          fill="none"
          fillOpacity="1"
          stroke="#a50000"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="2.379"
          paintOrder="fill markers stroke"
          ry="1.982"
        ></rect>
      </g>
    </svg>
  );
}

export default Icon;

