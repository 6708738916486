import * as React from "react"

const SvgComponent = ({strokeColor="#A50000", 
backgroundColor="white",
width="70%", 
height="70%"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="92mm"
    height="74mm"
    viewBox="0 0 92 74"
    style={{
      height: height,
      width: width
    }}
  >
    <path
      style={{
        fill: strokeColor,
        fillOpacity: 1,
        stroke: backgroundColor,
        strokeWidth: 2.61249,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="M71.4-1.128c-.034-.064-1.288.403-2.811 1.047L1.425 28.325c-1.523.644-2.722 1.214-2.688 1.278l19.666 37.219c.034.063 1.287-.404 2.81-1.048l67.164-28.406c1.523-.644 2.722-1.214 2.688-1.278zM59.461 11.803l1.507.518a3.893 5.192 73.537 0 0 .326 1.69 3.893 5.192 73.537 0 0 6.593 1.459 3.893 5.192 73.537 0 0 .899-.463l2.828.972 5.23 9.897-.39.939a3.893 5.192 73.537 0 0-1.832.49 3.893 5.192 73.537 0 0-2.924 5.485 3.893 5.192 73.537 0 0 1.644 1.51l-.4.96-42.22 17.856-1.137-.383a3.893 5.192 73.537 0 0-.34-1.464 3.893 5.192 73.537 0 0-6.593-1.46 3.893 5.192 73.537 0 0-.708.35l-1.425-.479-6.21-11.754.857-1.918a3.6 4.557 75.498 0 0 .513-.189 3.6 4.557 75.498 0 0 2.472-4.978 3.6 4.557 75.498 0 0-.412-.592l.442-.987zm5.646 5.97a6.749 6.749 0 0 0-6.71 6.749 6.749 6.749 0 0 0 6.75 6.749 6.749 6.749 0 0 0 6.748-6.75 6.749 6.749 0 0 0-6.749-6.748 6.749 6.749 0 0 0-.039 0zm-18.603 4.544a11.893 10.023 0 0 0-11.888 9.717h2.434a7.516 9.427 75.95 0 0 .649 3.641 7.516 9.427 75.95 0 0 12.172 3.068 7.516 9.427 75.95 0 0 5.723-6.71h2.798a11.893 10.023 0 0 0-11.888-9.716ZM28.273 35.865a4.272 5.201 77.727 0 0-2.424.495 4.272 5.201 77.727 0 0-2.731 5.832 4.272 5.201 77.727 0 0 6.77 1.812 4.272 5.201 77.727 0 0 2.732-5.83 4.272 5.201 77.727 0 0-4.347-2.31z"
    />
    <rect
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 0.116593,
        strokeLinecap: "butt",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      width={79.616}
      height={38.242}
      x={10.709}
      y={34.053}
      rx={2.534}
    />
    <path
      style={{
        fill: strokeColor,
        fillOpacity: 1,
        stroke: backgroundColor,
        strokeWidth: 2.65221,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="M13.254 30.147c-1.684 0-3.04.06-3.04.132v42.447c0 .073 1.356.131 3.04.131h74.258c1.684 0 3.04-.058 3.04-.13V30.278c0-.073-1.356-.132-3.04-.132zm14.694 7.48H73.59l1.115 1.077a5.261 3.96 0 0 0-.512 1.703 5.261 3.96 0 0 0 5.261 3.96 5.261 3.96 0 0 0 1.033-.077l2.093 2.02v11.288l-.802.721a5.261 3.96 0 0 0-1.89-.264 5.261 3.96 0 0 0-5.26 3.96 5.261 3.96 0 0 0 .763 2.055l-.82.738H27.893l-.845-.806a5.261 3.96 0 0 0 .391-1.499 5.261 3.96 0 0 0-5.26-3.96 5.261 3.96 0 0 0-.808.048l-1.057-1.009V44.177l1.69-1.45a4.61 3.666 0 0 0 .554.027 4.61 3.666 0 0 0 4.61-3.667 4.61 3.666 0 0 0-.088-.714zm23.635 3.399A11.893 10.023 0 0 0 39.69 51.048a11.893 10.023 0 0 0 11.894 10.023 11.893 10.023 0 0 0 11.893-10.023 11.893 10.023 0 0 0-11.893-10.022ZM30.47 44.634a7.216 6.749 0 0 0-7.216 6.749 7.216 6.749 0 0 0 7.216 6.748 7.216 6.749 0 0 0 7.216-6.748 7.216 6.749 0 0 0-7.216-6.75zm43.431.535a7.484 6.348 0 0 0-7.484 6.347 7.484 6.348 0 0 0 7.484 6.348 7.484 6.348 0 0 0 7.483-6.348 7.484 6.348 0 0 0-7.483-6.347z"
    />
  </svg>
)

export default SvgComponent
