import {useState}  from "react"

const SvgComponent = ({strokeColor="#A50000", 
                       backgroundColor="white",
                       width="70%", 
                       height="70%"
                      }) => {
                        



                        
    return (



  <svg 

  width="109mm" height="120mm" viewBox="0 0 109 120"
  style={{

    height: height,
    width: width
  }}
  >
    <g transform="translate(-8.05 -24.45)">
      <path
        style={{
          fill: backgroundColor,
          fillOpacity: 1,
          stroke: strokeColor,
          strokeWidth: 3.565,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M60.818 85.483c.205 1.895.412 4.295 3.208 6.115l30.008.125c2.131-.447 3.955-1.485 4.673-5.589-.772-10.733-2.606-31.893-3.43-33.58-3.806-7.796-24.487-8.754-30.68-.854-2.04 2.602-3.779 33.783-3.779 33.783z"
      />
      <path
        style={{
          fill: backgroundColor,
          fillOpacity: 1,
          stroke: strokeColor,
          strokeWidth: 3.565,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M26.517 85.105c.205 1.895.412 4.295 3.208 6.115l30.007.125c2.132-.447 3.956-1.484 4.674-5.589-.772-10.733-2.606-31.893-3.43-33.58-3.807-7.796-24.487-8.754-30.68-.854-2.04 2.602-3.78 33.783-3.78 33.783zM76.977 122.241c.205 1.895.412 4.295 3.208 6.115l30.007.125c2.132-.447 3.955-1.484 4.673-5.589-.772-10.732-2.606-31.893-3.43-33.58-3.806-7.796-24.486-8.754-30.68-.854-2.039 2.602-3.778 33.783-3.778 33.783z"
      />
      <path
        style={{
          fill: backgroundColor,
          fillOpacity: 1,
          stroke: strokeColor,
          strokeWidth: 3.565,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M10.264 121.671c.205 1.896.412 4.295 3.208 6.116l30.007.125c2.132-.448 3.956-1.485 4.674-5.59-.772-10.732-2.606-31.893-3.43-33.58-3.807-7.795-24.487-8.754-30.68-.853-2.04 2.601-3.78 33.782-3.78 33.782z"
      />
      <ellipse
        style={{
          opacity: 1,
          fill: backgroundColor,
          fillOpacity: 1,
          stroke: strokeColor,
          strokeWidth: 3.565,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
          paintOrder: "fill markers stroke",
        }}
        cx={46.104}
        cy={38.754}
        rx={10.824}
        ry={12.294}
      />
      <ellipse
        style={{
          fill: backgroundColor,
          fillOpacity: 1,
          stroke: strokeColor,
          strokeWidth: 3.565,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
          paintOrder: "fill markers stroke",
        }}
        cx={29.801}
        cy={74.435}
        rx={10.944}
        ry={12.949}
      />
      <ellipse
        style={{
          fill: backgroundColor,
          fillOpacity: 1,
          stroke: strokeColor,
          strokeWidth: 3.565,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
          paintOrder: "fill markers stroke",
        }}
        cx={79.914}
        cy={39.021}
        rx={10.824}
        ry={12.294}
      />
      <ellipse
        style={{
          fill: backgroundColor,
          fillOpacity: 1,
          stroke: strokeColor,
          strokeWidth: 3.565,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
          paintOrder: "fill markers stroke",
        }}
        cx={96.217}
        cy={74.568}
        rx={10.944}
        ry={12.949}
      />
      <path
        style={{
          fill: backgroundColor,
          fillOpacity: 1,
          stroke: strokeColor,
          strokeWidth: 3.565,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M37.464 134.974c.277 2.373.557 5.377 4.338 7.656l40.582.157c2.883-.56 5.349-1.859 6.32-6.998-1.044-13.437-3.525-39.93-4.638-42.042-5.148-9.76-33.116-10.961-41.492-1.07-2.758 3.258-5.11 42.297-5.11 42.297z"
      />
      <ellipse
        style={{
          fill: backgroundColor,
          fillOpacity: 1,
          stroke: strokeColor,
          strokeWidth: 3.565,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
          paintOrder: "fill markers stroke",
        }}
        cx={63.209}
        cy={74.568}
        rx={14.015}
        ry={17.223}
      />
    </g>
  </svg>
)}

export default SvgComponent
