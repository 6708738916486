const PHONE_REGEX = /^\d{10,11}/
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/


const INVALID_DEFAULT_MESSAGE = "Ce champ est invalide"

export class Validator{

    constructor(defaultErrorMessage=INVALID_DEFAULT_MESSAGE){
        this.errorMessage  = defaultErrorMessage
    }

    validate(value){
        throw new Error("Not Implemented")
    }
}

export class StringValidator extends Validator{

    constructor(defaultMessage=INVALID_DEFAULT_MESSAGE){
        super(defaultMessage)
        
    }

    validate(value){

        let trimValue = value.trim()
        if(trimValue !== ""){
          return {
            isValid :true,
            message : ""
          }
        }
        return {
          isValid : false,
          message : this.errorMessage
        }
        
    }
}

export class RegexValidator extends Validator{

    constructor(regex, defaultMessage=INVALID_DEFAULT_MESSAGE){
        super(defaultMessage)
        this.regex = regex
    }

    validate(value){ 

        let trimValue = value.trim()
        

        if(trimValue.search(this.regex) !== -1){
          return {
            isValid :true,
            message : ""
          }
        }



        return {
          isValid : false,
          message : this.errorMessage
        }
    }
}


export class PhoneValidator extends RegexValidator{

    constructor(defaultMessage=INVALID_DEFAULT_MESSAGE){
        super(PHONE_REGEX, defaultMessage)
    }

    validate(value){ 

        let trimValue = value.trim().replace(/[^0-9]/g, '')
        
        alert(trimValue)

        if(trimValue.search(this.regex) !== -1){
          return {
            isValid :true,
            message : ""
          }
        }



        return {
          isValid : false,
          message : this.errorMessage
        }
    }
}

export class EmailValidator extends RegexValidator{

    constructor(defaultMessage=INVALID_DEFAULT_MESSAGE){
        super(EMAIL_REGEX, defaultMessage)
    }
}


export function  updateField(event, setState) {
    let key = event.target.name;
    let newValue = event.target.value;
    setState(oldState => {
      let newModel = { ...oldState };
      newModel.model[key].errorMessage = ""
      newModel.model[key].value = newValue
      return newModel;
    })
  }


export function validate(oldModel){

    
    let model = {...oldModel}

    let isValidate = true;

    for (const [key, state] of Object.entries(model)) {

        if("validator" in model[key]){
            let {isValid, message} = model[key].validator.validate(state.value);


        
            if(!isValid){
                isValidate = false;
                model[key].errorMessage = message
            }
        }
       
    }

    
    
    return {
        isValid : isValidate,
        model :model

    }
}