import Regx from "../global/Regx"



function baseModel(name, errorMsg, isValid, required, value){
    this.name = name
    this.errorMsg = errorMsg
    this.isValid = isValid !== undefined ? isValid : (value) => {return true}
    this.required = required !== undefined ?  required : false
    this.value = value !== undefined ? value : ""
    this.msg = ""
}

function modelContactToData(model){

    let data = {}

    Object.entries(model).forEach((elem) => {

        data[elem[0]]  = elem[1].value
    })

    return data
}

function  model() {
                   
    this.first_name = new baseModel("first_name", "Ce champ est invalide", (value)=>{ return new RegExp(Regx.ALPHA).test(value)}, true)
    this.last_name = new baseModel("last_name", "Ce champ est invalide", (value)=>{ return new RegExp(Regx.ALPHA).test(value)}, true)
    this.email = new baseModel("email", "Ce champ est invalide", (value)=>{ return new RegExp(Regx.EMAIL).test(value)}, true ) 
    this.phone = new baseModel("phone", "Ce champ est invalide", (value)=>{ return new RegExp(Regx.PHONE_NUMBER).test(value)}, true )
    this.subject = new baseModel("subject", "Ce champ est invalide", undefined, true )
    this.content = new baseModel("content", "Ce champ est invalide", undefined, true )

    
};




export { model, modelContactToData}