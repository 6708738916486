export default function AccuseDeReception(firstName, lastName) {

    return `<html>
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    </head>
    <body
      style="
        margin: auto;
        max-width: 500px;
        width: 100%;
        background-color: grey;
        font-family: Roboto;
      "
    >
      <div
        class="wrapper"
        style="
          width: 50%;
          margin-left: auto;
          margin-right: auto;
          min-width: 500px;
          background-color: white;
        "
      >
        <header>
          <div
            class="container"
            style="
              margin: 0;
              padding: 0;
              background: url('https://myapart-static-content.s3.ca-central-1.amazonaws.com/oie_13174337IDkoa07Q.png')
                no-repeat center center;
              height: 150px;
              width: 100%px;
              background-size: cover;
            "
          >
            <h1 style="color: white; text-align: center; padding-top: 10px">
              Accusé de réception
            </h1>
          </div>
        </header>
  
        <div style="text-align: justify; margin-left: 4em; padding-right: 3em">
          <img
            width="250px"
            height="70px"
            alt="logo"
            src="https://myapart-static-content.s3.ca-central-1.amazonaws.com/logo.png"
          />
  
          <p>Bonjour ${firstName} ${lastName}.</p>
          <p>
            Ce message est pour vous informer que votre demande d’informations a
            bien été reçu.
          </p>
          <p>
            Un de nos gestionnaires immobiliers prendra contact avec vous dans un
            délai de 24 à 72 heures.
          </p>
          <p>Merci beaucoup de l’intérêt que vous portez pour MesApparts.</p>
  
          <p style="margin-top: 2.5em">
            L'équipe de direction
            <span class="block" style="display: block">MesApparts</span>
          </p>
        </div>
  
        <table
          style="
            text-align: center;
            color: white;
            background-color: #a50000;
            width: 100%;
            padding-top: 1em;
            margin-top: 40px;
          "
        >
          <tr style="border-bottom: 2px solid white">
            <td style="width: 30%"></td>
  
            <td>
              <a
                href="https://twitter.com/MesApparts"
                style="text-decoration: none; color: white"
              >
                <img
                  width="20px"
                  class="icofont icofont-twitter"
                  src="https://myapart-static-content.s3.ca-central-1.amazonaws.com/twitter.png"
                  style="width: 20px"
                />
              </a>
            </td>
  
            <td style="min-width: 20px">
              <a
                href="https://www.facebook.com/MesApparts"
                style="text-decoration: none; color: white"
              >
                <img
                  width="20px"
                  src="https://myapart-static-content.s3.ca-central-1.amazonaws.com/facebook.png"
                  alt="facebook-logo"
                />
              </a>
            </td>
            <td>
              <a
                href="https://ca.linkedin.com/company/mesapparts"
                style="text-decoration: none; color: white"
              >
                <img
                  width="20px"
                  class="icofont icofont-linkedin"
                  src="https://myapart-static-content.s3.ca-central-1.amazonaws.com/linkedin.png"
                />
              </a>
            </td>
  
            <td style="width: 30%"></td>
          </tr>
  
          <tr>
            <td colspan="5">
              <p>Copyright @MesApparts | Tous droits réservés</p>
            </td>
          </tr>
        </table>
      </div>
    </body>
  </html>
   `


}
