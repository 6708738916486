const getModelFromData = (data) =>{
    
    const schema = {
        "first_name" : "Prénom",
        "last_name" : "Nom de famille",
        "email" : "Email",
        "phone" : "Téléphone",
        "subject" : "Sujet",
        "content" : "Contenu"
    }

    let newModel = []

     Object.keys(schema).map((key) => {
        
        newModel.push({
            "name" : schema[key],
            "value" : data[key]
        })

    })

    return newModel
}


export default (data) => {


    let modelFromData = getModelFromData(data).map((elem) => {

        let value = elem.value

        if(value === true){
            value = "Oui"
        }else if(value === false){
            value = "Non"
        }

        return (<li>{elem.name + " : " + value }</li>)
    })


    return (<div >

        <h2 style={{
            color: 'white',
            height: '100px',
            backgroundColor: '#002E63',
            textAlign: 'center',
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: '20px'
        }}>Demande de contact</h2>

        <h3 style={{   paddingLeft: '20px'}}>Informations :</h3>
        <ul style={{
            listStyleType: 'none'
        }}>
            {modelFromData}
        </ul>

    </div>)
}