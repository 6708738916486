
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App"

import { ParallaxProvider } from 'react-scroll-parallax';


ReactDOM.render(
  <BrowserRouter>
  <ParallaxProvider>

    <App />
  </ParallaxProvider>

  </BrowserRouter>,
  document.getElementById("root")
);