import * as React from "react"

const SvgComponent = ({strokeColor, 
backgroundColor,
width="70%", 
height="70%"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="110mm"
    height="118mm"
    viewBox="0 0 110 118"
    style={{
      height: height,
      width: width
    }}
  >
    <ellipse
      style={{
        opacity: 1,
        fill: "none",
        fillOpacity: 1,
        stroke: backgroundColor,
        strokeWidth: 3.22872,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      cx={40.343}
      cy={39.566}
      rx={31.807}
      ry={32.074}
    />
    <ellipse
      style={{
        opacity: 1,
        fill: "none",
        fillOpacity: 1,
        stroke: backgroundColor,
        strokeWidth: 3.46553,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      cx={39.942}
      cy={39.833}
      rx={38.237}
      ry={38.37}
    />
    <rect
      style={{
        opacity: 1,
        fill: "none",
        fillOpacity: 1,
        stroke: backgroundColor,
        strokeWidth: 2.80873,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      width={44.943}
      height={19.036}
      x={105.143}
      y={-11.822}
      ry={0.756}
      transform="matrix(.66644 .74556 -.72365 .69017 0 0)"
    />
    <path
      style={{
        opacity: 1,
        fill: "none",
        stroke: backgroundColor,
        strokeWidth: 2.808,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="m61.858 71.238 7.217 8.018M67.738 66.16l7.484 8.285M84.576 76.583 70.678 88.877M100.88 94.757l-13.631 13.364"
    />
    <path
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 0.549507,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="M29.284 35.62c8.36-.943 15.868-.2 23.415.465l.314 3.26c3.516 3.636 1.679 6.651-1.886 9.466-3.602 7.89-2.555 11.049-9.743 10.882-10.597-.247-7.534-5.569-11-11.968-4.478-3.111-4.792-5.994-1.728-8.691z"
    />
    <path
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 0.549507,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="M29.284 35.62c-3.856.102-4.838-.272-4.816-1.035.041-1.44 1.33-1.305 2.82-2.122-.051-12.511 10.994-13.812 14.208-13.96 4.54 1.006 14.274 3.213 13.836 15.29 1.472.796 2.747-.033 2.86 1.965.041.729-2.291.648-5.493.327-7.972-1.143-15.738-1.072-23.415-.465z"
    />
    <path
      style={{
        fill: "none",
        stroke: "#fefefe",
        strokeWidth: 0.714772,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="m41.232 21.267-.167 10.754M37.398 21.925c-2.788 2.249-3.595 5.802-3.278 10.096M44.788 21.98c2.635 1.841 4.205 4.735 3.444 9.931"
    />
  </svg>
)

export default SvgComponent
