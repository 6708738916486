import * as React from "react"

const SvgComponent = ({strokeColor="#A50000", 
backgroundColor="white",
width="70%", 
height="70%"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="114mm"
    height="111mm"
    viewBox="0 0 114 111"
    style={{
      height: height,
      width: width
    }}
  >
    <defs>
      <path id="a" d="M239.474-73.766h108.511v41.16H239.474z" />
    </defs>
    <ellipse
      style={{
        opacity: 1,
        fill: strokeColor,
        fillOpacity: 1,
        stroke: backgroundColor,
        strokeWidth: 5.10984,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      cx={56.977}
      cy={55.337}
      rx={53.991}
      ry={52.922}
    />
    <ellipse
      style={{
        opacity: 1,
        fill: strokeColor,
        fillOpacity: 1,
        stroke: backgroundColor,
        strokeWidth: 5.10984,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      cx={57.225}
      cy={55.279}
      rx={28.934}
      ry={52.524}
    />
    <path
      style={{
        fill: strokeColor,
        stroke: backgroundColor,
        strokeWidth: 5.10984,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="M57.225 1.461V108.19M20.946 15.883c23.622 14.249 47.474 14.264 71.564-.496M4.05 54.592 110.4 56.081M19.952 93.301c21.209-7.715 41.809-17.11 72.558 1.489"
    />
    <rect
      style={{
        opacity: 1,
        fill: strokeColor,
        fillOpacity: 1,
        stroke: "#fff",
        strokeWidth: 0,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      width={69.228}
      height={24.389}
      x={22.53}
      y={43.2}
      ry={0.991}
    />
    <text
      xmlSpace="preserve"
      style={{
        fontSize: "11.7605px",
        lineHeight: 0,
        fontFamily: "Constantia",
        InkscapeFontSpecification: "Constantia",
        whiteSpace: "pre",
        shapeInside: "url(#a)",
        fill: "blue",
        fillOpacity: 1,
        stroke: backgroundColor,
        strokeWidth: 0,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="matrix(1.9344 0 0 1.9028 -438.542 184.106)"
    >
      <tspan x={239.473} y={-63.172}>
        <tspan
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontSize: "11.7605px",
            lineHeight: 1.25,
            fontFamily: "Arial",
            InkscapeFontSpecification: "Arial",
            letterSpacing: 0,
            fill: backgroundColor,
            fillOpacity: 1,
            stroke: strokeColor,
            strokeWidth: 0,
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
        >
          {"WWW"}
        </tspan>
      </tspan>
    </text>
  </svg>
)

export default SvgComponent
