import * as React from "react"

const SvgComponent = ({strokeColor="#A50000", 
backgroundColor="white",
width="70%", 
height="70%"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="160mm"
    height="128mm"
    viewBox="0 0 160 128"
    style={{
      height: height,
      width: width
    }}
  >
    <path
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: "#a50000",
        strokeWidth: 1.80948,
        strokeLinecap: "square",
        strokeLinejoin: "round",
        paintOrder: "fill markers stroke",
      }}
      d="M1.919 102.115h156.018c.21 0 .377.173.377.389v24.43c0 .215-.168.388-.377.388H1.919a.383.383 0 0 1-.378-.389v-24.43c0-.215.168-.388.378-.388z"
    />
    <path
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: "#a50000",
        strokeWidth: 1.67741,
        strokeLinecap: "square",
        strokeLinejoin: "round",
        paintOrder: "fill markers stroke",
      }}
      d="M17.658 60.367a.334.334 0 0 0-.33.34v33.332c0 .188.147.34.33.34h46.25c.182 0 .33-.152.33-.34V60.707a.334.334 0 0 0-.33-.34zM41.106 72.37a5.338 5.168 0 0 1 5.338 5.168 5.338 5.168 0 0 1-5.338 5.17 5.338 5.168 0 0 1-5.337-5.17 5.338 5.168 0 0 1 5.337-5.168z"
    />
    <ellipse
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: "#a50000",
        strokeWidth: 1.84517,
        strokeLinecap: "square",
        strokeLinejoin: "round",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      cx={109.853}
      cy={20.354}
      rx={17.064}
      ry={18.921}
    />
    <path
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: "#a50000",
        strokeWidth: ".325139px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="M76.468 94.903C79.925 61.804 88.6 43.977 107.922 42.56l-4.804 52.343-8.807-.118 1.144-29.708c-5.647 10.844-5.762 20.168-7.092 29.826zM142.864 95.026c-3.457-33.098-12.132-50.925-31.454-52.343l4.804 52.343 8.807-.117-1.144-29.709c5.647 10.845 5.762 20.169 7.092 29.826z"
    />
  </svg>
)

export default SvgComponent

