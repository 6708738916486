import * as React from "react"

const SvgComponent = ({strokeColor="white", 
backgroundColor="#A50000",
width="70%", 
height="70%"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="117mm"
    height="120mm"
    viewBox="0 0 117 120"
    style={{
      height: height,
      width: width
    }}
  >
    <path
      style={{
        fill : backgroundColor,
        stroke: strokeColor,
        strokeWidth: 5.41243,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="M57.732 4.025 6.066 57.116h13.16l.731 60.33h76.524V56.635l13.648.723-17.303-19.305.243-28.476-12.429-.483-.487 16.41 12.55 12.307Z"
    />
    <ellipse
      style={{
        opacity: 1,
        fill: strokeColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 1.69224,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      cx={39.058}
      cy={55.399}
      rx={4.825}
      ry={4.778}
    />
    <ellipse
      style={{
        opacity: 1,
        fill: strokeColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 1.69224,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      cx={76.108}
      cy={55.911}
      rx={4.825}
      ry={4.778}
    />
    <path
      style={{
        fill: strokeColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 4.14212,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="M33.848 66.622s-1.26 15.885-.172 18.087c.337.682 2.24.512 2.24.512l.173 19.624-.173-19.624 6.032.512 4.308 8.532-2.93 11.945 3.102-11.774-4.534-8.776s3.505.377 4.326-.724c1.28-1.716-.67-9.834-.67-9.834l8.042 3.44-8.103-3.44c-1.944-9.592-11.617-10.938-11.64-8.48ZM81.244 67.548s1.065 15.952-.05 18.086c-.345.661-2.246.375-2.246.375l-.413 19.6.413-19.6-6.037.141-4.413 8.262 2.783 12.117-2.957-11.957 4.64-8.492s-3.509.163-4.316-.988c-1.258-1.794.791-9.786.791-9.786l-8.084 2.943 8.145-2.94c2.062-9.466 11.75-10.218 11.744-7.76z"
    />
    <ellipse
      style={{
        opacity: 1,
        fill : backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 0,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      cx={53.921}
      cy={89.506}
      rx={0.388}
      ry={0.107}
    />
    <ellipse
      style={{
        opacity: 1,
        fill : backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 0.729668,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      cx={60.922}
      cy={82.253}
      rx={1.261}
      ry={1.121}
    />
    <path
      style={{
        fill: strokeColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 0.694134,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="m61.504 83.341-.49 5.273.13-1.536-.9-.13-.43-.107 1.33.237.12-1.793-1.145-.125 1.144.125"
    />
  </svg>
)

export default SvgComponent
