import React from "react";

function Icon({width, height, style}) {
  return (
    <svg
    width={width}
    height={height}
    style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-803.984 279.972 2159.48 186.612"
    >
      <path
        fill="#FFF"
        d="M967.44 374.151H1010.8100000000001V425.053H967.44z"
      ></path>
      <path fill="#FFF" d="M1036.554 310.488H1079.924V361.39H1036.554z"></path>
      <path fill="#FFF" d="M967.528 309.773H1010.898V360.675H967.528z"></path>
      <path
        fill="#FFF"
        d="M1036.011 377.702H1079.3809999999999V428.604H1036.011z"
      ></path>
      <path
        fill="#A50000"
        d="M1355.939 380.478l-59.976-19.11c-59.984-19.47-179.943-57.152-299.896-52.666-119.96 4.486-239.92 52.932-359.88 62.178-119.958 9.598-239.913-19.112-359.872-9.511-119.96 9.24-239.918 57.688-359.878 71.776-119.96 14.62-239.914-5.116-359.873-19.202-119.96-14.623-239.92-23.595-299.895-28.711l-59.984-4.754v86.128H1355.939z"
      ></path>
    </svg>
  );
}

export default Icon;
