import { useEffect, useState } from "react"
import useWindowSize from "../../hooks/useWindowSize"
import Button from "./Button"




function TextArea({label, className, id, style, name, setInputModel, }){
    const baseStyle =  {

        input : {
            width: '100%', 
            backgroundColor:'#ededee00', 
            border: 'unset', 
            borderBottom: '1px solid black'
        }, 
        row :{
            ...style,

            paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px',  width: '100%'  

        }
    }
    return(     
    
    <div className={className} 
        
        style={baseStyle.row} 
        
        >
            <label for={id} >{label}</label> 
            <textarea 
            onChange={

                (e) => {
                setInputModel((oldState) =>{

                    let state =  {
                        ...oldState
                    }

                    state[name].value =  e.target.value

                    return state

                })}
            }
            name={name}
                                   style={{
                                    width: '100%',
                                    border: 'unset', 
                                    borderBottom: '1px solid black', 
                                    backgroundColor: '#6d6d6d0d'
                                }}
                                rows= "5"
                                />
    </div>)
}


function isValid(formModel, setFormModel){

    let isValid = true
    Object.keys(formModel).forEach((key) => {
        let model =  formModel[key]

        if(!validateInput(setFormModel, model.value, model)){
            isValid = false

        }
    
    })

    return isValid
}



function validateInput(setInputModel, value, model){

        let isValid = true;



        if(model.required && value.trim() === ""){




            isValid = false
           setInputModel(oldState => {

                let state = {...oldState}

                state[model.name].msg  = "Ce champ est requis"

               return state
           })
        }else if(!model.isValid(value)){
            isValid = false
            setInputModel(oldState => {

                let state = {...oldState}

                state[model.name].msg  = state[model.name].errorMsg

               return state
           })
        }else{


            setInputModel(oldState => {
                let state = {...oldState}
                state[model.name].msg  = ""
                return state
            })

            
        }

        return isValid


}

function Field({field, onChange}){

    const size = useWindowSize()


    return(<div
        className="form-field"
        style={{
            paddingLeft: '20px', 
            paddingRight: '20px', 
            paddingTop: '20px',  
            width: '100%' , 
            maxWidth: size.width < 675 ? '100%' : 'unset'
        }} 
    >
    
        <p style={{color: '#a50000', height:'20px'}} class="error">{field.errorMessage}</p>

        <label for={field.id} >
                <span style={{color: '#a50000'}}>{ (field?.required ?? false) ? "* " : ""}</span>{field.label}
        </label> 
        <input 
             style={{            
                width: '100%', 
                backgroundColor:'#ededee00', 
                border: 'unset', 
                borderBottom: '1px solid black'
            }}
        id={field.id}
        value={field.value} name={field.key} onChange={onChange} type="text" />
    
    

    </div>)

}

function TextEntry({field, onChange}){

    const size = useWindowSize()


    return(<div
        className="form-field"
        style={{
            paddingLeft: '20px', 
            paddingRight: '20px', 
            paddingTop: '20px',  
            width: '100%' , 
            maxWidth: size.width < 675 ? '100%' : 'unset'
        }} 
    >
    
        <p style={{color: '#a50000', height:'20px'}} class="error">{field.errorMessage}</p>

        <label for={field.id} >
                <span style={{color: '#a50000'}}>{ (field?.required ?? false) ? "* " : ""}</span>{field.label}
        </label> 

        <textarea 
               style={{            
                width: '100%', 
                backgroundColor:'#9696960d', 
                border: 'unset', 
                borderBottom: '1px solid black',
                height: "100px"
            }}
          id={field.id}
          value={field.value} name={field.key} onChange={onChange}
        />

    

    </div>)

}




function Input({  
    className, 
    style,
    id,
    label,
    type,
    name, 
    inputModel,
    setInputModel}){

   const size = useWindowSize()
   const model = inputModel[name]




    return(       
        <div className={className} 
        style={{
            paddingLeft: '20px', 
            paddingRight: '20px', 
            paddingTop: '20px',  
            width: '100%' , 
            maxWidth: size.width < 675 ? '100%' : 'unset',
            ...style
        }} 
        >
            <p style={{color: '#a50000', height:'20px'}}>{ model.msg !== undefined ?  model.msg : "" }</p>
            <label for={id} >
                <span style={{color: '#a50000'}}>{ model.required === true ? "* " : ""}</span>{label}
        </label> 

            <input 

            onChange={(e)=>{

                validateInput(setInputModel, e.target.value.trim(), model)

                setInputModel(oldState => {
                    let state = {...oldState}
                    state[model.name].value = e.target.value
                    return state
                })
            }}  

            name={name}
            value={model.value}
            type={type === undefined ? "text" : type} 
            id={id} 
            style={{            
                width: '100%', 
                backgroundColor:'#ededee00', 
                border: 'unset', 
                borderBottom: '1px solid black'
            }}  />
        </div>
    )
}


function findElementByType(name, children){

    let elements = []
    
    if(children !== undefined){
        
        children.forEach(child => {
            
               if("type" in child){
                   if(child.type.name === name){
                       elements.push(child)
                   }else if("children"  in child.props && Array.isArray(child.props.children) ){

                       elements = elements.concat(findElementByType(name, child.props.children))
                   }
               }
                
            })


    }

    return elements
  
}

function Form({children, onSubmit, submit, style, model}) {
    
    return (<form 




    onSubmit={(e) =>{
        e.preventDefault()
        if(onSubmit !== undefined){
            onSubmit(e)
        }
    }}

    style={{ display: 'flex', flexWrap: 'wrap', ...style}} 
  >


        {children}

          
        {submit}

        
        </form>)
}

function Category({children,  style, title}){

    const size = useWindowSize()

    
    

    return (
        <div 
        
        style={{
            width: '100%', 
            display: 'flex', 
            flexWrap: (size.width <= 900 ? "wrap" : "nowrap"),
            ...style
        }}

        className="form-category">

            {title === undefined ? title: ""}
            {children}
        </div>
    )
}

function Choice({ field, children, style, onChange }){

    return (

    <div>

        <p style={{color: '#a50000',  marginBottom: '2em'}}>{ field.errorMessage !== undefined ?  field.errorMessage : "" }</p>
        <label for={field.id} ><span style={{color: '#a50000'}}>{ field.required === true ? "* " : ""}</span>{field.label}</label> 
        <select
            name={field.key} 
            onChange={onChange}
            
            style={{
                display :"block",
            }}
        >
        {children}
        </select>
    </div>)

}


function Select({containerStyle, children,label, className, id, style, inputModel, setInputModel, name}){

   const model = inputModel[name]



    return (
        <div
        style={{...containerStyle}}
        >
            <p style={{color: '#a50000',  marginBottom: '2em'}}>{ model.msg !== undefined ?  model.msg : "" }</p>

            <label for={id} ><span style={{color: '#a50000'}}>{ model.required === true ? "* " : ""}</span>{label}</label> 
            <select 
            
            onChange={(e)=>{



                setInputModel((oldState) =>{


        
                    let state =  {
                        ...oldState, 
                    }
                    


                    validateInput(setInputModel, e.target.value, model)

        
                    state[name].value =  e.target.value
        
                    return state
        
                })}}

            className={className}
            style={{
                ...style
            }}
            id={id}>
                {children}
            </select>
        </div>
    )
}

function Option({value, text}){

    return(<option
    
    value={value}
    >
        {text}
    </option>)
    
}

function Checkbox({style, className,label, id, name, value, setInputModel,onClick}){


    return(<div

        style={{
            ...style
        }}

    >
        <input 
        onClick={onClick}

    defaultChecked={value}

     onChange={(e)=>{



        setInputModel((oldState) =>{

            let state =  {
                ...oldState
            }

            state[name].value =  e.target.checked



            return state

        })}}

        style={{margin: '10px'}} className={className} id={id} name={name} value={value} type="checkbox"  />
        <label for={id} >{label}</label>
    </div>)
    
}










export default  Form = {
    Form : Form, 
    Field :Field,
    Input : Input,
    Text: TextEntry, 
    TextArea : TextArea,
    Select : Select,
    Choice : Choice,
    Option : Option,
    Checkbox : Checkbox,
    Category : Category,
    findElementByType, 
    validateInput,
    isValid
}