
import Logo from '../../logo_fr';
import Hamburger from '../../hamburger.jsx';
import useWindowSize from "../../hooks/useWindowSize"
import {  useState } from "react";




export default function Menu(){

    const size = useWindowSize()

    const [active, setActive] = useState(false)


    const handleClick = (hamburger) => {
        setActive(!active)
    }


    return(

        <nav 
     
        className={`navbar ${active ? "navbar-active" : ""}`}>
            <li 
                className='brand-logo'
            style={{
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-between'}}>
                <a
                style={{




                }}
                href="/"
                title="Logo de MesApparts"
                >
                    <Logo width={size.width > 500 ? '300px' : '200px'} />
                </a>
                <Hamburger handleClick={handleClick}  visible={ size.width < 1401} />
               
            </li>
            <li
            
            className="pop-soumission"
            style={{
                cursor: 'pointer',
                padding: '10px',
                fontSize: '1.5em',
                verticalAlign: 'center',
                display: 'flex', 
                alignItems: 'center', 
                textAlign: 'center',
                borderRadius: '20px',
                color: 'white',
                boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"

            }}>
              
                <a title="Lien vers le formulaire de soumission" href="/soumission">Besoin d'une soumission?

                <div
                 className="magnifyer">
            <i 
            
            class="icofont-search-property"> </i>

               
                </div>
                
                </a>
                
                
         
            </li>
            <li>
                <ul 
                style={{padding: 0}}
                className="link">
                    <li><a href="/accueil">ACCUEIL</a></li>
                    <li><a href="/nos-services">NOS SERVICES</a></li>
                    {/* <li><a href="/nos-services">Locataires</a></li> */}
                    <li><a href="/contactez-nous">CONTACTEZ-NOUS</a></li>
                    <li><a href="/apropos">À PROPOS</a></li>
                </ul>
            </li>
            
        </nav>
    
     
     
)
}