import * as React from "react"

const SvgComponent = ({strokeColor="white", 
backgroundColor="#A50000",
width="70%", 
height="70%"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="89mm"
    height="98mm"
    viewBox="0 0 89 98"
    style={{
      height: height,
      width: width
    }}
  >
    <path
      style={{
        opacity: 1,
        fill: backgroundColor,
        stroke:strokeColor,
        strokeWidth: 3.879,
        strokeLinecap: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      d="M35.209 72.975 43.57 63.1l3.142.354 8.245 9.237s4.954-20.842 3.213-12.19c0 0 17.166 3.085 22.442 9.214 5.914 6.869 6.473 26.41 6.473 26.41-3.643.034-13.734-.108-13.734-.108l-2.205-15.165 2.205 15.165-22.124-.004L48 73.976l3.965-5.005L48 73.976l3.228 22.037-12.52.041 3.22-22.004-3.996-4.765 3.997 4.765-3.22 22.004-21.348.3 1.33-15.665-1.33 15.665s-11.972.13-14.847.15c.182-.96 1.386-17.316 5.752-24.85 2.204-3.802 9.01-10.032 23.824-10.87-1.255-9.397 3.119 12.19 3.119 12.19z"
    />
    <path
      style={{
        opacity: 1,
        fill: backgroundColor,
        stroke:strokeColor,
        strokeWidth: 3.879,
        strokeLinecap: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      d="M24.987 41.113c-9.68-.186-5.032-11.939-3.102-10.981 4.806-2.823 16.275 1.99 30.895-8.815 1.35 2.61 6.863 7.432 10.958 7.879 2.333.254 4.42-.32 4.42-.32l-4.188.686.881 11.67c.227 2.998-10.79 15.858-19.48 16.057-8.689.198-12.653-5.266-12.653-5.266s-7.617-7.517-7.731-10.91c-.261-7.8.583-11.548.583-11.548l-3.388.528s-.487-11.894 2.653-16.491C29.064 7.412 36.131 2.557 44.22 2.199c6.268-.279 13.085 1.703 17.182 11.499 0 0 5.146-.06 6.12 3.433 1.134 4.075.636 11.745.636 11.745C76.3 39.547 65.305 41.79 64.85 41.232"
    />
  </svg>
)

export default SvgComponent
