import React from "react";

function Icon({width, height, style}) {
  return (
    <svg 
    style={style}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg" viewBox="2.167 2.687 81.322 93.119">
      <path
        fill="rgba(0, 0, 0, 0)"
        stroke="#A50000"
        strokeLinecap="round"
        strokeWidth="2.379"
        d="M33.822 71.986l7.735-9.384 2.906.337 7.627 8.777s4.582-19.806 2.972-11.584c0 0 15.88 2.932 20.76 8.756 5.471 6.528 5.987 25.098 5.987 25.098-3.369.032-12.704-.103-12.704-.103l-2.039-14.411 2.039 14.411-20.465-.003-2.986-20.942 3.668-4.757-3.668 4.757L48.64 93.88l-11.581.039 2.978-20.911-3.697-4.528 3.697 4.528-2.978 20.911-19.748.283 1.23-14.885-1.23 14.885s-11.075.125-13.734.143c.169-.911 1.282-16.454 5.321-23.614 2.038-3.613 8.336-9.534 22.039-10.329-1.161-8.93 2.885 11.584 2.885 11.584h0z"
      ></path>
      <path
        fill="rgba(0, 0, 0, 0)"
        stroke="#A50000"
        strokeLinecap="round"
        strokeWidth="2.379"
        d="M24.366 41.707c-8.955-.177-4.655-11.345-2.869-10.436 4.445-2.682 15.053 1.892 28.577-8.376 1.25 2.479 6.35 7.063 10.138 7.488 2.159.241 4.088-.305 4.088-.305l-3.874.652.816 11.091c.209 2.849-9.983 15.069-18.02 15.258-8.038.189-11.705-5.004-11.705-5.004s-7.046-7.144-7.151-10.368c-.242-7.413.538-10.973.538-10.973l-3.133.501s-.45-11.303 2.455-15.672C28.136 9.68 34.673 5.068 42.157 4.727c5.798-.264 12.104 1.619 15.893 10.928 0 0 4.761-.058 5.662 3.263 1.05 3.871.588 11.16.588 11.16 7.533 10.141-2.638 12.272-3.058 11.743"
      ></path>
    </svg>
  );
}

export default Icon;
