import { useState, useRef } from 'react';

export default function({show, msg, onClick}){

    const [opacity, setOpacity] = useState(1)

    return(show && <div
        className="error-form"
        style={{
            backgroundColor: '#a50000', 
            color: 'white',padding: '15px', 
            width: '100%', textAlign: 'center',
            borderRadius: '20px', fontSize: '1.2em', 
            display: 'flex', justifyContent: 'space-between', 
            alignItems: 'center'
        }}
        >
            <p style={{margin: 0}}>{msg}</p>
            <div 

            onClick ={() => {
                setOpacity(1)
                onClick()
            }}
            onMouseEnter={
                () => {setOpacity(0.5)}
            }
            onMouseLeave={
                () => {setOpacity(1)}
            }
            onTouchStart={
                () =>{setOpacity(0.5)}
            }
            onTouchEnd={
                ()=>{
                    setOpacity(1)
                }
            }

            style={{
            opacity:opacity,
            backgroundColor: 'white', padding: '0px 5px',  color: '#a50000', borderRadius: '50%', cursor: 'pointer', width: '30px'}}><span>X</span></div>
        </div>)

}