export default function InternalServerError(){
    return (<div
    style={{
        minHeight: '500px',
        margin: '2em',
        marginTop: '100px'
    }}
    >
    <h2>Internal server error</h2>
    <h3>500</h3>
    <h4>Une erreur est survenue, veuillez réessayer plus tard.</h4>
    </div>)
}