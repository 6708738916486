import Soumission from '../../images/soumission.jpeg'
import Arrow from '../../images/proposal/arrow.jsx'
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';
import { H2 } from "../global/Title"
import Header from "../global/Header"
import Slider from "react-slick";
import { FORFAITS, Forfait, ServiceNom, SERVICES } from "../../components/Service"
import { Validator, EmailValidator, StringValidator, PhoneValidator, validate, updateField } from "../../components/Validation"
import React, { useState, useRef, useEffect, useContext } from 'react';
import Form from "../global/Form"
import Button from "../global/Button"
import { model, modelToData } from "./model"
import { useOnScroll } from "../../hooks/useOnScroll"
import useWindowSize from "../../hooks/useWindowSize"
import { mailGun } from "../global/mailApi"
import newProposal from "../../templates/newProposal";
import AccuseDeReception from "../../templates/accuseDeReception";
import { useHistory } from 'react-router-dom';
import { renderToString } from 'react-dom/server'
import axiosConfig from '../../axiosConfig'

async function sendProposalConfirmation(modelData) {
    

    const template = renderToString(newProposal(modelData))

    const data = {
        from: "info@mesapparts.ca",
        to: "info@mesapparts.ca",
        subject: "Nouvelle soumission reçu",
        html: template
    };

    return mailGun.asyncSend(data)
}

async function sendConfirmationDeReception(modelData) {

    

    const template = AccuseDeReception(modelData.firstName, modelData.lastName)

    const data = {
        from: "info@mesapparts.ca",
        to: modelData.email,
        subject: "Accusé de réception",
        html: template
    };

    return mailGun.asyncSend(data)

}

function PrevButton({ cls, style, onClick }) {

    return (<div
        className={cls}
        style={{ ...style }}
        onClick={onClick}
    >{"<"}</div>)
}

function NextButton({ cls, style, onClick }) {

    return (<div
        className={cls}
        style={{ ...style }}
        onClick={onClick}
    >{">"}</div>)
}

function Window({
    id,
    component,
    nom,
    options
}) {

    const Component = component;

    return (<div id={`w-${id.toLowerCase()}`} className="vs-window">

        <h2>{nom.toUpperCase()}
            <span className="underbar"></span>
        </h2>


        {typeof component === 'function' ? <Component doNext={options?.doNext} /> : component}

    </div>)
}

const VSContext = React.createContext();

function VerticalSlider({ children, handleSubmit }) {

    let [state, setState] = useState({
        currentIndex: 0,
        completed: [0],
        lastClick: 0
    })

    const { x, y } = useOnScroll();
    const breakpoint = (getTopParalax() ?? 0) - ((getTopNav() ?? 0) / 2)

    function getTopNav() {

        return document.getElementsByTagName("nav")[0]?.getBoundingClientRect().height
    }

    function getTopParalax() {

        return document.getElementsByClassName("parallax-inner")[0]?.getBoundingClientRect().height

    }

    function getTopHeight() {
        return (document.getElementsByClassName("vs-menu-proposal")[0].getBoundingClientRect().height +
            getTopNav()
        )
    }

    function getTopWindow(n) {
        return document.getElementsByClassName("w-container")[n].getBoundingClientRect().y
    }

    const next = (elem, index) => {

        let isValid = true;

        if (elem.props.validation) {
            isValid = elem.props.validate()
        }

        if (isValid) {

            setState(oldState => {
                let newState = { ...oldState }

                if (!oldState.completed.includes(index + 1)) {
                    newState.completed.push(index + 1)
                }

                newState.lastClick = Math.min(state.lastClick + 1, children.length - 1)

                return newState
            })

            window.scrollTo({
                top: getTopParalax() - getTopHeight(),
                behavior: 'smooth'
            })
        }

    }

    const previous = (elem, index) => {

        setState(oldState => {
            let newState = { ...oldState }

            newState.lastClick = Math.max(0, newState.lastClick - 1)

            return newState
        })

        window.scrollTo({
            top: getTopParalax() - getTopHeight(),
            behavior: 'smooth'
        })

    }


    return (
        <VSContext.Provider value={{
            doNext: next
        }}>
            <div className="vs">
                <div className={"vs-menu-proposal"}>
                    <ul className={(y > breakpoint ? "fixed" : "")}>
                        {
                            children.map((elem, index) => (
                                <li
                                    className={"" + (state.lastClick === index ? "current" : "") + (state.lastClick - 1 === index ? "last" : "")}
                                    onClick={(e) => {
                                        if (state.completed.includes(index)) {
                                            setState(oldState => ({
                                                ...oldState,
                                                lastClick: index
                                            }))
                                        }

                                    }}
                                >
                                    <span
                                        className="index"

                                    >{index + 1}</span>
                                    <Arrow
                                        className={"arrow " + (state.completed.includes(index) ? "completed " : "") +
                                            (state.lastClick === index ? "current" : "")}
                                        title={elem.props.nom} />

                                </li>


                            ))
                        }

                    </ul>

                </div>
                <div className="vs-windows">
                    {
                        children.slice(0, state.completed[state.completed.length - 1] + 1).map((elem, index) => {
                            return (<div className={"w-container" + (index !== state.lastClick ? " disapear minimize hide" : "")} id={`w-${index}`}>

                                {elem}

                                <div className="vs-buttons">

                                    {
                                        index !== 0 && (elem.props.options?.showPrevious ?? true) ? <Button.Button
                                            onClick={previous}

                                            text="Précédent" style={{
                                                padding: "1em"
                                            }} /> : ""}

                                    {
                                        index < children.length - 1 && (elem.props.options?.showNext ?? true) ? <Button.Button
                                            onClick={(event) => {

                                                let isValid = true;

                                                if (elem.props.validate) {
                                                    isValid = elem.props.validate()
                                                }

                                                if (isValid) { next(elem, index) }

                                            }}
                                            text="Suivant" style={{
                                                padding: "1em"
                                            }} /> : ""}

                                    {

                                        index === children.length -2 ? <Button.Button
                                        onClick={handleSubmit}
                                        className="vs-buttons"
                                        text="Soumettre"
                                        /> : ""
                                    }


                                </div>
                            </div>)
                        })
                    }

                </div>



            </div>
        </VSContext.Provider>
    )

}

const DEFAULT_SETTINGS = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    className: 'caroussel',
    responsive: [

        {
            breakpoint: 769,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },

        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,

            },
        },

        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
    ],
    prevArrow: <PrevButton cls="btn-carousel btn-prev" />,
    nextArrow: <NextButton cls="btn-carousel btn-next" />
}


function ServicesSlider({
    settings,
    children
}) {

    settings = {
        ...DEFAULT_SETTINGS,
        ...settings
    }




    return (<Slider
        {
        ...settings
        }
    >
        {children}
    </Slider>)


}


export default function Proposal() {

    const [proposalModel, setProposalModel] = useState(new model())


    const [fInfosContact, setFInfosContact] = useState({
        model: {
            firstName: {
                label: "Prénom",
                initialValue: "",
                value: "",
                key: "firstName",
                validator: new StringValidator(),
                errorMessage: "",
                required: true,
                id: "c-first-name"
            },
            lastName: {
                label: "Nom de famille",
                initialValue: "",
                value: "",
                key: "lastName",
                validator: new StringValidator(),
                errorMessage: "",
                required: true,
                id: "c-last-name"
            },
            email: {
                label: "Courriel",
                initialValue: "",
                value: "",
                key: "email",
                validator: new EmailValidator(),
                errorMessage: "",
                required: true,
                id: "c-email"
            },
            phoneNumber: {
                label: "Téléphone",
                initialValue: "",
                value: "",
                key: "phoneNumber",
                validator: new StringValidator(),
                errorMessage: "",
                required: true,
                id: "c-phone-number"
            }
        }
    })


    const [fBesoins, setFBesoins] = useState({

        model: {
            autresBesoins: {
                label: "Autres besoins ou demandes",
                initialValue: "",
                value: "",
                key: "autresBesoins",
                validator: new StringValidator(),
                errorMessage: "",
                required: false,
                id: "c-autres-besoins"
            },

            statsHeardAboutUs: {
                label: "Où avez-vous entendu parler de nous?",
                initialValue: "",
                value: "",
                key: "statsHeardAboutUs",
                validator: new StringValidator(),
                errorMessage: "",
                required: true,
                id: "c-stats-heard-about-us"
            },
            statsHeardAboutUsOther: {
                label: "Autre :",
                initialValue: "",
                value: "",
                key: "statsHeardAboutUsOther",
                validator: new StringValidator(),
                errorMessage: "",
                required: true,
                id: "c-stats-heard-about-us-other"
            }

        }
    })


    const [services, setServices] = useState({
        servicesSelected: [],
        services: Object.values(SERVICES)
    })

    const history = useHistory()


    useEffect(() => {

        document.body.className = "hide-proposal-pop"

        return () => {
            document.body.className = ""
        }
    }, [])





    const Slider = ({ onSelect }) => {

        const { doNext } = useContext(VSContext)



        return (<ServicesSlider>
            {
                Object.entries(FORFAITS).map(elem => {

                    const [key, value] = elem;

                    return (<Forfait
                        nom={value.nom}
                        services={value.services}
                        onSelect={(services) => {

                            onSelect(services)
                            doNext({ props: {} }, 0)
                        }}
                    />)

                })
            }
        </ServicesSlider>)
    }


    const handleSubmit = async (e) => {

        e.preventDefault()

        

        let modelData = modelToData({
            ...proposalModel,
            ...fBesoins.model,
            ...fInfosContact.model
        })

        let data = {...modelData,  
            servicesSelected : services.servicesSelected.map(elem => elem.model.modal.title.simple + " " +elem.model.modal.title.emphasis)}

        

        try {

            //Post data
            await axiosConfig.post("/proposal", data)

            let response = await Promise.all([

                sendConfirmationDeReception(data),

                sendProposalConfirmation(data)

            ])

            history.push("/soumission-reussie")

        } catch (e) {

            history.push("/500")
        }




    }



    return (
        <ParallaxProvider>

            <Header
                image={Soumission}
                title="BESOIN D'UNE SOUMISSION?"
                style={{ marginBottom: 0 }}
            />

            <section id="proposal"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}
            >



                <VerticalSlider
                handleSubmit={handleSubmit}
                >

                    <Window
                        id='forfaits'
                        nom={"Forfaits"}
                        options={{
                            showNext: false,
                            doNext: () => {

                            }
                        }}
                        component={
                            (<Slider onSelect={(services) => {

                                setServices({
                                    servicesSelected: services.filter(elem => elem.disponible).map(elem => elem.src)
                                })
                            }} />)
                        } />

                    <Window
                        id='services'
                        nom={"Services"}
                        component={<div className='w-services'>
                            <div className="services-list">
                                <h3>Sélectionner vos services</h3>
                                <ul >
                                    {
                                        Object.values(SERVICES).slice(0, 15).map(value => {

                                            const Picto = value.picto;

                                            const selected = services.servicesSelected.find(elem => elem.model.modal.picto == value.model.modal.picto) !== undefined;

                                            return (

                                                <li
                                                    onClick={(e) => {
                                                        e.preventDefault()

                                                        const selected = services.servicesSelected.find(elem => elem.model.modal.picto == value.model.modal.picto) !== undefined;

                                                        if (!selected) {
                                                            setServices({
                                                                servicesSelected: [...services.servicesSelected, value]
                                                            })
                                                        } else {
                                                            const index = services.servicesSelected.findIndex(elem => elem.model.modal.picto == value.model.modal.picto);
                                                            setServices({
                                                                servicesSelected: [...services.servicesSelected.slice(0, index),
                                                                ...services.servicesSelected.slice(index + 1)]
                                                            })
                                                        }

                                                    }}
                                                    className="service">

                                                    <Picto
                                                        {...value.model}
                                                        width="80px"
                                                        height="70px"
                                                    />

                                                    <span className="title">{value.model.modal.title.simple + " " + value.model.modal.title.emphasis}</span>
                                                    {selected ? <span className="add"><i class="icofont-tick-mark"></i></span> : ""}

                                                </li>)
                                        })
                                    }
                                </ul>
                            </div>

                        </div>} />

                    <Window
                        id='infos-immo'
                        nom={"Informations Immobilières"}
                        component={<Form.Form

                            onSubmit={() => { }}
                            ressourceName="propopsal"
                            style={{
                                maxWidth: '1300px', backgroundColor: 'white', borderRadius: '2%',
                                padding: '10px',
                                boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
                            }}



                        >
                            <Form.Category

                            >
                                <Form.Input
                                    inputModel={proposalModel}
                                    setInputModel={setProposalModel}

                                    name="nb_immeubles" defaultValue={0} type="number" label="Nombre d'immeubles" className="input-immeuble" id="input-nb-immeubles" />
                                <Form.Input
                                    inputModel={proposalModel}
                                    setInputModel={setProposalModel}

                                    name="nb_unites_total" defaultValue={0} type="number" label="Nombre d'unités total" className="input-nb-toal" id="input-nb-units" />
                                <Form.Select
                                    inputModel={proposalModel}
                                    setInputModel={setProposalModel}
                                    name="categorie_biens_immobiliers"

                                    containerStyle={{
                                        paddingLeft: '20px',
                                        paddingRight: '20px'
                                    }}
                                    style={{
                                        width: '100%',
                                        minWidth: '200px'

                                    }}
                                    label="Catégorie de biens immobiliers"
                                    className="input-property-category"
                                    id="input-property-category">

                                    <Form.Option value="Condominium" text="Condominium" />
                                    <Form.Option value="small-owner" text="1 à 5 logements" />
                                    <Form.Option value="large-owner" text="6 et plus" />
                                    <Form.Option value="half-commercial" text="Semi-Commercial" />
                                    <Form.Option value="commercial" text="Commercial" />
                                    <Form.Option value="industrial" text="Industriel" />

                                </Form.Select>
                            </Form.Category>

                            <Form.Category>
                                <Form.Input
                                    inputModel={proposalModel}
                                    setInputModel={setProposalModel}

                                    name="revenus_brutes_par_an"
                                    label="Revenus brutes des immeubles par an ($)" className="input-income-brut" id="input-income-brut" />
                                <Form.Select
                                    inputModel={proposalModel}
                                    setInputModel={setProposalModel}

                                    name="delai_de_gestion"

                                    containerStyle={{
                                        paddingLeft: '20px',
                                        paddingRight: '20px'
                                    }}
                                    style={{
                                        width: '100%'
                                    }}
                                    label="Je souhaite faire gérer mes immeubles dans un délai de"
                                    className="input-management-interval"
                                    id="input-management-interval">

                                    <Form.Option value="3-months" text="1 à 3 mois" />
                                    <Form.Option value="6-months" text="3 à 6 mois" />
                                    <Form.Option value="6-month-more" text="6 mois et plus" />
                                </Form.Select>
                            </Form.Category>
                        </Form.Form>} />

                    <Window
                        validate={() => {

                            let { isValid, model } = validate(fInfosContact.model)


                            if (!isValid) {
                                setFInfosContact({
                                    ...fInfosContact, model: {
                                        ...fInfosContact.model,
                                        ...model
                                    }
                                })
                            }

                            return isValid
                        }}
                        id='infos-contacts'
                        nom={"Informations de contacts"}
                        component={<Form.Form
                            model={proposalModel}
                            onSubmit={() => { }}
                            ressourceName="propopsal"
                            style={{
                                maxWidth: '1300px', backgroundColor: 'white', borderRadius: '2%',
                                padding: '10px',
                                boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
                            }}


                        >
                            <Form.Category>

                                <Form.Field
                                    field={fInfosContact.model.firstName}
                                    onChange={(event) => {
                                        updateField(event, setFInfosContact)
                                    }} />

                                <Form.Field
                                    field={fInfosContact.model.lastName}
                                    onChange={(event) => {
                                        updateField(event, setFInfosContact)
                                    }} />



                            </Form.Category>

                            <Form.Category>

                                <Form.Field
                                    field={fInfosContact.model.email}
                                    onChange={(event) => {
                                        updateField(event, setFInfosContact)
                                    }} />
                                <Form.Field
                                    field={fInfosContact.model.phoneNumber}
                                    onChange={(event) => {
                                        updateField(event, setFInfosContact)
                                    }} />

                            </Form.Category>


                        </Form.Form>

                        } />

                    <Window
                        options={{
                            showNext: false
                        }}
                        validate={() => {

                            let { isValid, model } = validate(fInfosContact.model)

                            if (!isValid) {
                                setFInfosContact({
                                    ...fInfosContact, model: {
                                        ...fInfosContact.model,
                                        ...model
                                    }
                                })
                            }

                            return isValid
                        }}

                        id='infos-besoins'
                        ressourceName="propopsal"
                        style={{
                            maxWidth: '1200px', backgroundColor: 'white', borderRadius: '2%',
                            padding: '10px',
                            boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
                        }}
                        nom={"Autres besoins"}
                        component={<><Form.Form

                            onSubmit={() => { }}
                            ressourceName="propopsal"
                            style={{
                                maxWidth: '1300px', backgroundColor: 'white', borderRadius: '2%',
                                padding: '10px',
                                boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
                            }}

                        >

                            <Form.Text
                                field={fBesoins.model.autresBesoins}
                                onChange={(event) => {
                                    updateField(event, setFBesoins)
                                }}
                            />
                            <Form.Category>

                                <Form.Choice
                                    onChange={(e) => {
                                        setFBesoins((oldState) => {
                                            let newState = { ...oldState }
                                            newState.model.statsHeardAboutUs.value = e.target.value
                                            return newState
                                        })
                                    }}
                                    field={fBesoins.model.statsHeardAboutUs}>



                                    <Form.Option value="" text="Sélectionner..." />
                                    <Form.Option value="facebook" text="Facebook" />
                                    <Form.Option value="word_of_mouth" text="Bouche à oreille" />
                                    <Form.Option value="meeting" text="Rencontre immobiliere" />
                                    <Form.Option value="door_to_door" text="Porte à porte" />
                                    <Form.Option value="google" text="Google" />
                                    <Form.Option value="twitter" text="Twitter" />
                                    <Form.Option value="mail" text="Poste" />
                                    <Form.Option value="email" text="E-mail" />
                                    <Form.Option value="other" text="Autre" />

                                </Form.Choice>


                                {
                                    fBesoins.model.statsHeardAboutUs.value == "other" ?

                                        <Form.Field
                                            field={fBesoins.model.statsHeardAboutUsOther}
                                            onChange={(event) => {
                                                updateField(event, setFBesoins)
                                            }}
                                        />

                                        : ""
                                }


                            </Form.Category>
                        </Form.Form>


                        </>


                        } />

                    <Window
                        options={{
                            showNext: false,
                            showPrevious: false
                        }}
                        id="infos-done"
                        nom={"Terminé!"}
                        component={() => (<></>)}
                    />

                    

                </VerticalSlider>

            </section>

            <div
                style={{
                    width: '100%',
                    height: '100px',
                    backgroundColor: '#a50000'
                }}
            ></div>
        </ParallaxProvider>
    )
}