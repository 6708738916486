import Bloc from '../../images/bloc1.jpeg'
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';
import useWindowSize from "../../hooks/useWindowSize"
import Form from "../global/Form"
import Button from "../global/Button"
import {model, modelContactToData} from "./models"
import Header from "../global/Header"
import { useState } from 'react';
import axiosConfig from '../../axiosConfig'
import { renderToString } from 'react-dom/server'
import {socketLab, mailGun} from "../global/mailApi"
import PopUp  from "../global/PopUp";
import { useHistory } from 'react-router-dom';
import newContact from "../../templates/newContact";






 

export default function ContactUs() {

    const [modelContact, setModelContact] = useState(new model())
    const [showErrorMsg, setShowErrorMsg] = useState(false)
    const history = useHistory()


    
    const handleSubmit =  (e) => {
       
        e.preventDefault()

        let isValid = Form.isValid(modelContact, setModelContact)
        let modelData = modelContactToData(modelContact)


        if(isValid){

            const data = {
                from: "info@mesapparts.ca",
                to: "info@mesapparts.ca",
                subject: "Demande de contact",
                html: renderToString(newContact(modelData)),
            };   
                

            mailGun.asyncSend(data).then(
                (r) => {

                    history.push("/confirmation-reception")

                }
            ).catch((err) => {console.log(err)})
    
    

        }else{

            window.scrollTo(0,300)
            setShowErrorMsg(true)

        }

    }



    return (
        <ParallaxProvider>

            <Header
            style={{marginBottom: 0}}
            image={Bloc}
            title="CONTACTEZ-NOUS"
            />
     
            <div style={{display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', padding: '5px', margin: '20px'}}>
            <section id="contact">
            <ul
                   style={{
                    listStyle: 'none',
                    textAlign: 'left',
                    padding: '0', 
                    fontSize: '1.4em'

                }}
            >   

               <li
               style={{
                   textAlign: 'center',
                    fontWeight:'bold',
                    textAlign: 'left',
                    padding: '10px'


                }}
               >INFORMATIONS</li>
               <li
                         style={{padding: '10px'}}
               ><i class="icofont-home"></i> Gestion Immobilière - MesApparts</li>

                <li
                     style={{padding: '10px'}}
                ><i class="icofont-google-map"></i> 213-630 Rue Francis Byrne, Québec, QC G1H 7N4</li>

                <li
                     style={{padding: '10px'}}
                ><i class="icofont-phone"></i> (581) 443-1417</li>
                <li
                     style={{padding: '10px'}}
                ><i class="icofont-mail"></i> <a
                className="mail-to"
                >info@mesapparts.ca</a></li>

            </ul>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2728.999793558569!2d-71.27061748392826!3d46.84369397914148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb897b74217f965%3A0x7420dab4752d62d7!2sMesApparts!5e0!3m2!1sfr!2sca!4v1633319738196!5m2!1sfr!2sca" 
                width="100%" 

                 allowfullscreen="" loading="lazy"></iframe>
            </section>
            <section  id="contact-form">
                <Form.Form
                onSubmit={handleSubmit}
                style={{ width: '90vw', minWidth: '300px' ,maxWidth: '600px'}}
                submit={
                    <Button.Submit/>
                }
                >
                            <PopUp 
            show={showErrorMsg}
            msg={"Veuillez corriger les erreurs indiquées"}
            onClick={() =>{
                setShowErrorMsg(false)
            }}/>
                    <Form.Input inputModel={modelContact} setInputModel={setModelContact} name="first_name" style={{maxWidth: '300px'}}  label="Prénom" className="first-name" id="first-name"/>
                    <Form.Input inputModel={modelContact} setInputModel={setModelContact} name="last_name" style={{maxWidth: '300px'}}  label="Nom de famille" className="last-name" id="last-name"/>
                    <Form.Input inputModel={modelContact} setInputModel={setModelContact} name="email" style={{maxWidth: '300px'}} label="Email" className="email" id="email"/>
                    <Form.Input inputModel={modelContact} setInputModel={setModelContact} name="phone" style={{maxWidth: '300px'}} label="Numéro de téléphone" className="phone" id="phone"/>
                    <Form.Input inputModel={modelContact} setInputModel={setModelContact} name="subject" label="Sujet" className="subject" id="subject"/>
                    <Form.TextArea inputModel={modelContact} setInputModel={setModelContact} name="content"  label="Écrivez votre message ici" className="content" id="content"/>

                </Form.Form>
            </section>
       
            </div>
            <div
        style={{
           width: '100%',
           height: '100px', 
           backgroundColor : '#a50000'
        }}
        >


        </div>
        </ParallaxProvider>

    )
}