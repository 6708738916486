const getModelFromData = (data) =>{
    
    const schema = {
        "firstName" : "Prénom",
        "lastName" : "Nom de famille",
        "email" : "email",
        "phoneNumber" : "Numéro de téléphone", 
        "nb_immeubles" : "Nombre d'immeubles",
        "nb_unites_total" : "Nombre d'unités total", 
        "categorie_biens_immobiliers" : "Catégorie de biens immobiliers", 
        "revenus_brutes_par_an" : "Revenu brute par an",
        "delai_de_gestion" : "Délai de gestion",
        "autresBesoins" : "Autres besoins",
        "statsHeardAboutUs" : "Ou avez-vous entendu parler de nous?",
        "statsHeardAboutUsOther" : "Autre",
    }


    let newModel = []

     Object.keys(schema).map((key) => {
        
        newModel.push({
            "name" : schema[key],
            "value" : data[key]
        })

    })


    return newModel


}

export default (data) => {


    let soumission = getModelFromData(data).map((elem) => {

        let value = elem.value

        if(value === true){
            value = "Oui"
        }else if(value === false){
            value = "Non"
        }

        return (<li>{elem.name + " : " + value }</li>)
    })


    return (<div >

        <h2 style={{
            color: 'white',
            height: '100px',
            backgroundColor: '#a50000',
            textAlign: 'center',
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: '20px'
        }}>NOUVELLE SOUMISSION</h2>

        <p>Vous avez reçu une nouvelle soumission de la part de {data["firstName"] + " " + data["lastName"]}!</p>

        <h3>Informations :</h3>
        <ul style={{
            listStyleType: 'none'
        }}>
            {soumission}
        </ul>

        <h3>Services sélectionnés : </h3>
        <ul>
            {
                data.servicesSelected.map((service) =><li>{service} : Oui</li>)
            }
        </ul>

    </div>)
}