import Notebook from '../../images/notebook.jpeg'
import Mathieu from '../../images/profiles/mathieu_morin.jpeg'
import Fred from '../../images/profiles/federik_lavoie.jpeg'
import Groupe from '../../images/profiles/groupe.jpeg'
import Header from '../global/Header'
import {H2} from '../global/Title'
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';

import useWindowSize from "../../hooks/useWindowSize"



const FaceCard = ({ className,style, image, width, height, name, role, imgLeft, children }) => {

    const size = useWindowSize()

    const faceCard = {
        img:
        {
            position: 'relative',
            borderRadius: '200px',
            padding: '5px',
            border: '1px solid #f3f0f0b8',
        },

        container: {
            marginBottom: '20px',
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            backgroundColor: 'white',
            width: '340px',
            height: '350px',
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'

        }, 
        p : { 
            alignSelf: 'flex-end',
            maxWidth: '400px',
        }
    }

    if (size.width > 1400 && imgLeft == true){
        faceCard.container.order = 1
    }


    return (
        <div 
        className={"face-card " + className}
        style={
            
            {
                
                ...style,
                display: 'flex',  maxWidth: '900px'}}>
            <div style={faceCard.container}>
                <img style={faceCard.img} width={width} height={height} src={image} />
                <p style={{ marginBottom: '5px', fontSize: '3vh' }}>{name}</p>
                <p style={{ margin: 0, color: '#00000085' }}>{role}</p>
                <p style={{ fontSize: '3vh' }}> 

                {children}

        
     
                    <a 
                    class="icon"
                    style={{color: 'black'}}
                    href="https://www.facebook.com/MesApparts"> 
                        <i class="icofont-facebook"></i>
                    </a>  

                    <a
                    class="icon"
                    style={{color: 'black'}}
                    href="https://twitter.com/MesApparts"
                    >
                        <i class="icofont-twitter"></i>
                    </a>


                 
                </p>
            </div>
            
        </div>
    );
}

export default function About() {



    return (
   


      
        <ParallaxProvider
        >

            <Header 
            image={Notebook}
            title="NOTRE COMPAGNIE DE GESTION"
            />
     <div
        id="about"
        style={{
            width: '70%',
            margin: '0 auto',
            textAlign: 'justify'
        }}
        >
            <section
            id="about-history"
            style={{
                marginBlock: '5vh',
            
            }}
            >
                <H2
                color='black'
                borderColor="#a50000"
                >
                    NOTRE HISTOIRE
                </H2>

                <p
                 style={{
                    display: 'block',
                  margin: ' 0 20px',
                  fontSize : '1.2em'
                
                }}
                >
                MesApparts est une entreprise de gestion immobilière fondé à Québec. Nous offrons un service de gestion de propriétés en tout genre à Québec et ses alentours. Nous contribuons à cette communauté par notre expérience en gestion, en administration, en finance et en programmation informatique. Fondée en 2021, l’entreprise est jeune et se veut être dynamique et avant-gardiste parfait pour maximiser le potentiel de votre propriété.
                </p>
            </section>
      
<div
style={{

    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
}}q
>
<FaceCard

className="facecard-mathieu"
image={Mathieu}
width='200px'
height='200px'
name='Mathieu Morin'
role='Vice-président'
style={{

    margin: '20px 20px'
}}
>

<a class="icon" style={{color: 'black'}} href="https://www.linkedin.com/in/mathieu-morin-706b98140/">
    <i class="icofont-linkedin"></i>
</a>

</FaceCard> 
                              
            <FaceCard
                        image={Fred}
                        width='200px'
                        height='200px'
                        name='Frédérik Lavoie'
                        role='Président'
                        imgLeft={true}
                        style={{

                            margin: '20px 20px'
                        }}
                        

                    >
                    
                    <a class="icon" style={{color: 'black'}} href="https://www.linkedin.com/in/fr%C3%A9d%C3%A9rik-lavoie-98a986115/">
                        <i class="icofont-linkedin"></i>
                    </a>
                        </FaceCard> 
</div>
            <section
        
            style={{
                backgroundColor: '#a50000',
                color: 'white',
                boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                padding: '30px', 
                borderRadius: '5px', 
                marginBottom: '30px'
            }}
            >
            

                <H2
                color='white'
                borderColor="white"
                >
                    HISTOIRE DES FONDATEURS
                </H2>



                    <p
                    style={{   fontSize : '1.2em'}}
                    >
                
                    En 2021, Frédérik Lavoie et Mathieu Morin ont ouvert 
                    une entreprise en gestion immobilière appelée Gestion 
                    IFLMM inc. 
                
                    Malgré les gros joueurs du domaine, ils 
                    avaient créé un service voulant être proche des acteurs 
                    de l’immobilier et de sa communauté.     

                    MesApparts a été conçu pour offrir un service de choix 
                    aux propriétaires de biens immobiliers locatifs et aux 
                    syndicats de copropriétés. Elle permet un choix 
                    de services de gestion selon vos besoins pour vous 
                    permettre une tranquillité d’esprit. 

                   </p>
                   
               
                   <p
                       style={{   fontSize : '1.2em'}}
                   > 

                        Fredérik et Mathieu étaient ravis de venir en aide à la
                        gestion immobilière par la mise en commun de
                        leurs forces en administration et en informatique. 
              
                        En effet, 
                        dynamiser le domaine et comprendre les
                        problématiques pour en trouver des solutions
                        étaient les motivations primaires de la fondation de
                        l’entreprise.
                    </p>  




            </section>

            <section
            style={{
                margin: '5vh',
                display: 'flex',
                justifyContent: 'center'
            }
             
            }
            >

            </section>

            <section
             style={{
                marginBottom : '5vh',
                clear: 'both'
                
            
            }}
            id="about-culture"
            >

                <H2
                    color='black'
                    borderColor="#a50000"
                >
                    CULTURE
                </H2>

                <p
                style={{padding: ' 0 20px',   fontSize : '1.2em'}}
                >
MesApparts permet de créer un environnement immobilier sain et humain. Nos motivations contribuent à améliorer le travail d’équipe et votre satisfaction. Ce qui nous différencie fait notre force.
 
 Construire une organisation plus efficace, inclusive et humaine profite à nos équipes, nos clients et notre communauté. Nous aidons les membres de notre équipe à évoluer dans leurs fonctions actuelles et à imaginer des solutions innovatrices pour le futur de l’entreprise et de notre clientèle.
 
                </p>
                

             
            </section>
            
            </div>

        </ParallaxProvider>


    )
}