import React from "react";

function Icon({width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 503 149"
    >
      <defs>
        <path id="rect864" d="M247.196 -97.518H520.095V-55.185H247.196z"></path>
        <path id="rect858" d="M255.51 -150.339H477.365V-104.682H255.51z"></path>
        <path d="M13.607 214.69H315.23199999999997V296.33299999999997H13.607z"></path>
        <path d="M52.161 261.56H290.089V295.437H52.161z"></path>
      </defs>
      <g>
        <path
          fill="#fff"
          fillOpacity="1"
          stroke="#003c52"
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.809"
          d="M11.761 106.773c16.592 6.465 23.695 7.82 39.41 7.903 10.266.06 19.153-.399 27.52-.858 5.64-.34 9.37-.83 14.918-1.234 12.98-1.476 20.145-2.615 31.342-3.833 6.226-.677 17.75-.87 17.75-.87l28.695-.075c6.682.387 14.553.742 19.796 1.707 10.892 2.39 18.285 5.025 25.579 8.507 8.982 6.481 11.66 9.72 14.431 13.006-4.393-3.39-7.378-7.11-13.97-9.98-8.65-3.25-17.394-5.523-26.288-6.726-4.588-.734-14.05-1.275-20.587-1.519-15.34-.073-21.915.584-28.218 1.664-3.589.386-8.601 1.403-16.729 3.47-6.747 2.044-12.767 5.119-18.29 8.896-10.083 4.836-16.14 7.334-27.309 10.405-10.332 2.202-27.238 3.437-35.611 3.327-9.21.092-18.904-.134-32.865-1.764z"
        ></path>
        <text
          xmlSpace="preserve"
          style={{
            lineHeight: "1.25",
            whiteSpace: "pre",
            inlineSize: "164.413",
          }}
          x="46.041"
          y="230.645"
          fill="#fff"
          fillOpacity="1"
          stroke="none"
          strokeWidth="0.265"
          fontFamily="sans-serif"
          fontSize="70.556"
          fontStyle="normal"
          fontWeight="normal"
        >
          <tspan x="46.041" y="230.645" visibility="hidden">
            <tspan dx="0 59.462387 41.754585 48.231361 43.959457 42.37471 30.110138 27.801941">
              MyAparts
            </tspan>
          </tspan>
        </text>
        <text
        fill="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4"
          strokeWidth="1"
          fontFamily="Constantia"
          fontSize="10.583"
          transform="translate(6.728 189.744)"
          xmlSpace="preserve"
          style={{
            lineHeight: "1.25",
            InkscapeFontSpecification: "Constantia",
            whiteSpace: "pre",
          }}
        >
          <tspan x="255.51" y="-116.347">
            <tspan style={{}} fontSize="38.806" fontWeight="bold">
              MesApparts
            </tspan>
          </tspan>
        </text>
        <text
        fill="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4"
          strokeWidth="1"
          fontFamily="Constantia"
          fontSize="10.583"
          transform="translate(24.568 183.696)"
          xmlSpace="preserve"
          style={{
            lineHeight: "1.25",
            InkscapeFontSpecification: "Constantia",
            whiteSpace: "pre",
          }}
        >
          <tspan x="247.197" y="-77.74">
            <tspan fontSize="22.578">Gestion immobilière</tspan>
          </tspan>
        </text>
      </g>
      <path
        fill="#fff"
        fillOpacity="1"
        stroke="#000"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="1.809"
        d="M34.509 112.946l.091-32.761 15.258-8.606v-17.43l-.092 17.647 28.86 9.474-.091-31.749 15.35-8.823-.093-19.454-.091 19.382 31.71 8.172V31.803l16.176-9.836V5.84v16.127l31.343 10.631-.092 31.532 17.555-9.04-.184-18.225.184 18.37 27.206 9.907.092 53.662c.991-.164-14.325-7.216-26.655-9.257-8.66-1.216-12.183-1.513-19.009-1.914-5.896-.172-11.057-.14-18.939.044-10.756-.123-26.748.912-27.586 1.074-9.24 1.125-20.022 2.371-31.71 3.689-4.694.452-2.924.48-15.44 1.446-13.357.979-20.329.798-27.483.65-5.676-.1-10.346-.334-16.36-1.59z"
      ></path>
      <path
        fill="#a50000"
        fillOpacity="1"
        stroke="#fff"
        strokeOpacity="1"
        strokeWidth="1.102"
        d="M50.909 80.662l22.62 7.983.142 23.82s-6.333.686-11.265.699c-4.933.012-11.273-.075-11.273-.075z"
        opacity="0.997"
        paintOrder="fill markers stroke"
      ></path>
      <path
        fill="#a50000"
        fillOpacity="1"
        stroke="#fff"
        strokeOpacity="1"
        strokeWidth="1.798"
        d="M142.89 35.728l23.29 7.378.422 62.683-24.072.254z"
        opacity="0.997"
        paintOrder="fill markers stroke"
      ></path>
      <path
        fill="#a50000"
        fillOpacity="1"
        stroke="#fff"
        strokeOpacity="1"
        strokeWidth="1.583"
        d="M94.68 51.623l24.63 6.668.422 49.218c-8.968 1.365-17.447 2.036-25.587 2.934z"
        opacity="0.997"
        paintOrder="fill markers stroke"
      ></path>
      <path
        fill="#a50000"
        fillOpacity="1"
        stroke="#fff"
        strokeOpacity="1"
        strokeWidth="1.566"
        d="M191.292 65.103l20.062 7.499.756 41.296s-2.25-.973-8.4-2.971c-4.515-1.763-12.31-3.25-12.31-3.25z"
        opacity="0.997"
        paintOrder="fill markers stroke"
      ></path>
      <path
        fill="#a50000"
        fillOpacity="1"
        stroke="#fff"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeOpacity="1"
        strokeWidth="0.228"
        d="M12.775 114.798c7.908 2.235 7.334 1.726 21.228 4.715-3.669-.069 9.242-.151 16.393-.007 12.892.01 20.872-.684 28.466-1.432 4.726-.578 9.685-1.34 14.65-2.106 2.97-.028 19.528-2.955 32.275-4.951 3.395-.365 8-.774 16.508-1.33 10.105-.408 19.745-.552 30.936-.41 6.447.288 12.64.874 18.597 1.74 9.226 1.786 17.585 3.757 25.598 8.489 6.02 3.57 9.061 7.354 13.388 11.045-6.095-4.933-10.62-8.83-14.42-10.517-7.434-3.31-16.048-5.56-24.835-7.176-7.164-.727-12.734-.887-18.458-1.33a299.673 299.673 0 00-31.066.716c-5.023.704-10.32 1.48-16.378 2.455-14.348 3.586-22.447 8.287-31.456 11.25-4.574 1.59-9.552 2.705-14.819 3.477-9.823 2.255-19.712 3.365-28.85 4.002-5.595.3-11.23-.268-17.164-.831-8.95-1.497-10.768-.592-20.757-3.452z"
      ></path>
    </svg>
  );
}

export default Icon;
