// First we need to import axios.js
import axios from 'axios';


let apiKey = ""
let apiUrl = ""




if(process.env.REACT_APP_ENV === 'staging'){

    apiKey = process.env.REACT_APP_API_KEY_STAGING
    apiUrl = process.env.REACT_APP_API_URL_STAGING

}else if(process.env.REACT_APP_ENV === 'production'){

    apiKey = process.env.REACT_APP_API_KEY_PROD
    apiUrl = process.env.REACT_APP_API_URL_PROD

}else if (process.env.REACT_APP_ENV === 'development'){

    apiKey = process.env.REACT_APP_API_KEY_DEV
    apiUrl = process.env.REACT_APP_API_URL_DEV

}else{
    apiUrl = process.env.REACT_APP_API_KEY_DEV
apiKey = process.env.REACT_APP_API_URL_DEV
}

const instance = axios.create({
    baseURL: apiUrl
});




instance.defaults.headers.common['API_KEY'] = apiKey;


export default instance;