const mailgun = require("mailgun-js");
const { SocketLabsClient } = require('@socketlabs/email');
const serverId = 40066;
const injectionApiKey = "q7HGy9f2W8End4C3Ksk6";
const DOMAIN = "mesapparts.ca";

function MailApi(client){


    this.client = client
    
    this.send = (data, success, error) => {
        console.error("I am a virutal method")
    }

    this.asyncSend = async (data, success, error) => {
        console.error("I am a virtual method")
    }


}



function MailGun(config){
    MailApi.call(this, mailgun(config))

    this.asyncSend = async (data) => {
       const response =  await this.client.messages().send(data)
       return response
    }
}

function SocketLab(config){
    MailApi.call(this, new SocketLabsClient(config.serverId, config.injectionApiKey))
    this.asyncSend = async (data) => {

        const response =  await this.client.send(data)
       
        return response
       
    }
    this.asyncSend.bind(this)

}


const mailGun = new MailGun({ apiKey: "770854c26e6d8e7e979615c6023fe491-1f1bd6a9-c71408c3", 
                              domain: DOMAIN }); 

const socketLab = new SocketLab({serverId: serverId, injectionApiKey : injectionApiKey})



export  {
    mailGun,
    socketLab
}