import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 310">



      
      <path
      fill="#a50000"
        d="M0 64l48 21.3C96 107 192 149 288 176s192 37 288 26.7C672 192 768 160 864 144s192-16 288 5.3c96 21.7 192 63.7 240 85.4l48 21.3V0H0z"
      ></path>
    
    </svg>
  );
}

export default Icon;
