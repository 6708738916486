import * as React from "react"

const DEFAULT = {
  width : "100%",
  height : "100%",
  stroke : "black",
  fill : "#a50000", 
  title: "",
  className: "arrow"
}

const SvgComponent = (props) => {

  let defaultProps = {
    ...DEFAULT,
    ...props,
  }

  const {stroke, fill, width, height, title ,className} = defaultProps;

return (

  <>

    <span className="title">{title}</span>

    
<svg
className={className}
preserveAspectRatio="none"
height="100%"
width="100%"
viewBox="0 0 128 63" 

xmlns="http://www.w3.org/2000/svg">
  <path 
  d="M 63.033 -31.87 L 94.474 -12.462 L 95.782 94.372 L 65.168 74.264 L 33.61 96.604 L 31.584 -10.769 L 63.033 -31.87 Z" transform="matrix(-0.019353, 0.999813, -0.999813, -0.019353, 97.27639, -30.677675)"/>
</svg>

  </>
  
)
    }

export default SvgComponent
