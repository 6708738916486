import React from "react";

function Icon({width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 128 175"
    >
      <g stroke="#a50000" strokeDasharray="none" strokeWidth="2.379">
        <g transform="translate(-180.673 -8.315)">
          <rect
            width="0.459"
            height="29.638"
            x="264.413"
            y="-105.321"
            fill="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.714"
            opacity="1"
            paintOrder="fill markers stroke"
            ry="0.02"
            transform="matrix(.74717 .66464 -.67184 .7407 0 0)"
        fill='white'

          ></rect>
          <rect
            width="0.499"
            height="32.269"
            x="119.899"
            y="266.61"
            fill="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.714"
            opacity="1"
            paintOrder="fill markers stroke"
            ry="0.022"
            transform="rotate(-41.644) skewX(.09)"
        fill='white'

          ></rect>
          <path
            fill="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            d="M205.997 57.452c22.004 22.665 49.328 64.934 76.918 96.951 9.384 7.106 22.544-2.487 16.631-17.198l-86.934-86.934v-7.56l-15.686-10.583-6.426 6.993 9.26 18.331z"
            opacity="1"
        fill='white'

          ></path>
        </g>
        <g fillOpacity="1">
          <path
            fill="#fff"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            d="M80.717 53.157l-43.298 50.78c-29.443-3.455-28.711 22.208-26.994 28.866L20.881 122l6.115-.423 5.88 6.949.534 7.483-9.621 11.225c5.044 2.459 30.998 2.158 25.123-28.865l44.634-49.712c22.224 1.418 28.522-9.552 25.123-28.063l-8.82 10.156h-6.681l-6.95-7.216.535-6.682 9.087-12.027c-8.118-4.464-31.038 5.15-25.123 28.33z"
            opacity="1"
          ></path>
          <circle
            cx="44.412"
            cy="109.424"
            r="0.945"
            fill="#a50000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.714"
            opacity="1"
            paintOrder="fill markers stroke"
          ></circle>
          <circle
            cx="85.045"
            cy="63.122"
            r="0.945"
            fill="#a50000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.714"
            opacity="1"
            paintOrder="fill markers stroke"
          ></circle>
        </g>
        <rect
          width="0.685"
          height="44.198"
          x="105.12"
          y="-0.72"
          fill="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="1.714"
          opacity="1"
          paintOrder="fill markers stroke"
          ry="0.03"
          transform="matrix(.74806 .66363 -.6581 .75293 0 0)"
        ></rect>
      </g>
    </svg>
  );
}

export default Icon;
