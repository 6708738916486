import * as React from "react"

const SvgComponent = ({strokeColor="#A50000", 
backgroundColor="white",
width="70%", 
height="70%"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="105mm"
    height="111mm"
    viewBox="0 0 105 111"
    style={{
      height: height,
      width: width
    }}
  >
    <path
      style={{
        opacity: 1,
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "butt",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="m10.72 23.747 12.473-13.229 6.614 6.237c3.99-2.45 7.903-4.99 13.607-5.48l.567-10.017h17.954v9.26c4.723 1.12 9.434 2.277 13.418 5.859l6.615-5.67 13.04 12.473-5.67 6.993c2.205 4.121 4.6 7.946 5.103 14.74l9.26.379.568 19.844-10.206.189c-.446 5.477-2.592 9.821-5.292 13.796l6.237 7.37-12.284 13.985-6.804-6.803c-4.095 3.054-8.638 4.54-13.229 5.859l-.567 10.016H43.792V99.72c-4.472-1.204-8.945-2.955-13.418-5.67l-6.992 6.426L9.963 87.058l6.993-7.181c-2.986-4.43-5.077-9.305-6.048-14.741l-9.827.189.378-19.655h9.45c1.228-4.89 2.592-9.794 6.047-14.93z"
    />
    <ellipse
      style={{
        opacity: 1,
        fill: backgroundColor,
        stroke: strokeColor,
        strokeWidth: 2.379,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      cx={52.675}
      cy={55.639}
      rx={28.636}
      ry={30.384}
    />
    <path
      style={{
        opacity: 1,
        fill: backgroundColor,
        stroke: strokeColor,
        strokeWidth: 2.03936,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      d="m61.908 41.355 7.411 7.014-19.263 20.703L36.22 55.255l8.076-7.444 5.808 5.597z"
    />
  </svg>
)

export default SvgComponent
