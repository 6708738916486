import Menu from "./Menu"
import Footer from "./Footer"



export default function Frame({children}){

    
    const wrapperClassName =  children.props?.className ?? "";

    console.log(children.props)



    return (
        <div >
            <Menu/>
            <div className={("wrapper " + wrapperClassName).trim()}>
                {children}
            </div>
            <Footer/>
        </div>
      
    )
}