import React from "react";

function Icon({width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 128 122"
    >
      <g fill="#fff" fillOpacity="1" stroke="#a50000">
        <rect
          width="45.97"
          height="41.397"
          x="18.801"
          y="26.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.409"
          opacity="1"
          paintOrder="fill markers stroke"
          ry="8.018"
        ></rect>
        <rect
          width="45.97"
          height="41.397"
          x="64.771"
          y="26.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.409"
          opacity="1"
          paintOrder="fill markers stroke"
          ry="8.018"
        ></rect>
        <rect
          width="46.255"
          height="15.796"
          x="18.524"
          y="51.71"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.342"
          opacity="1"
          paintOrder="fill markers stroke"
          ry="7.898"
        ></rect>
        <rect
          width="46.255"
          height="15.796"
          x="64.486"
          y="51.701"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.342"
          opacity="1"
          paintOrder="fill markers stroke"
          ry="7.898"
        ></rect>
        <path
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeWidth="2.379"
          d="M16.93 45.581s10.302-1.843 10.156 7.217l-.234 14.465 75.136.568.869-15.434c.543-9.656 10.824-6.816 10.824-6.816s6.682 2.98 6.682 6.682v23.252a6.667 6.667 0 01-6.682 6.682H16.93a6.667 6.667 0 01-6.682-6.682V52.263a6.667 6.667 0 016.682-6.682z"
          opacity="1"
          paintOrder="fill markers stroke"
        ></path>
        <path
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="2.379"
          d="M23.879 82.197l-2.94 8.152c-.327 5.431 6.243 6.446 8.552 2.94l7.217-10.958z"
          opacity="1"
        ></path>
        <path
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="2.379"
          d="M107.016 82.099l2.94 8.151c.327 5.432-6.243 6.447-8.552 2.94l-7.217-10.958z"
          opacity="1"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
