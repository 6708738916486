import * as React from "react"

const SvgComponent = ({strokeColor="#A50000", 
backgroundColor="white",
width="70%", 
height="70%"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="110mm"
    height="106mm"
    viewBox="0 0 110 106"
    style={{
      height: height,
      width: width
    }}
  >
    <path
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: backgroundColor,
        strokeWidth: ".308348px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="M41.75.626c-9.698-2.963-11.163 11.5-8.265 19.546-22.295.6-29.072 21.338-23.791 30.458 2.369.312 4.738 1.159 7.106 3.14l-1.622 21.274C11.574 80.793 8.046 86.75 4.441 92.707c1.867 3.146 4.143 6.085 6.412 9.027l-10.197-.078.078 4.239h108.45c-3.823-14.678-10.706-28.968-19.465-43.018-7.05-.34-13.723.127-14.908 12.324-.898.56-2.723.805-4.172 1.178l-3.785-4.71-5.716 7.379L45.69 68.843c-3.756-12.652-2.891-26.536-3.863-39.565-2.383-2.007-3.711-4.3-3.012-7.143C48.133 18.316 51.448 3.589 41.75.625Z"
    />
    <path
      style={{
        fill: strokeColor,
        fillOpacity: 1,
        stroke: backgroundColor,
        strokeWidth: ".308348px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="m23.984 60.286-.386 16.093c-3.653 6.55-6.314 12.698-8.96 18.84l.618 6.28 15.526-.079.077-26.219c-1.354-6.043-3.88-10.747-6.875-14.915ZM39.665 68.843l.386 24.963c2.329 2.056 3.958 4.315 2.008 7.614l7.493.236c2.112-5.72 5.952-8.742 10.737-10.284-.658-1.446-.577-2.767 0-4.003l-4.326-3.062 2.55-2.826zM35.648 41.053l-4.944 13.031 5.485 8.714 3.398 2.355c-4.105-11.717-3.435-16.207-3.94-24.1ZM16.491 50.081c1.49-5.086 3.456-10.011 6.18-14.68 1.008-3.2-.281-3.773-.772-5.26-5.362 1.971-9.053 5.47-8.497 17.035z"
    />
  </svg>
)

export default SvgComponent
