import * as React from "react"

const SvgComponent = ({strokeColor="#A50000", 
backgroundColor="white",
width="70%", 
height="70%"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="102mm"
    height="102mm"
    viewBox="0 0 102 102"
        style={{
      height: height,
      width: width
    }}
  >
    <path
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: backgroundColor,
        strokeWidth: 2.165,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="m9.165 35.554 18.285.378-3.544 14.552c-1.367 7.101-.574 9.784 1.266 10.436 1.84.651 3.748.217 5.538-5.475l-1.181 6.946c1.49 4.84 4.494 5.837 9.26 2.362l.19-3.78-.19 3.497c3.15 5.235 6.3 7.009 9.45.944 3.398 3.103 5.482 2.995 8.126-1.7l-.378-17.387c5.215 4.683 15.436 6.712 17.86-.85-1.358-.946-4.039-1.89-6.615-2.836l-3.213-7.181 22.962.189v1.323l-1.795-.095C89.147 49.8 66.859 70.9 49.653 70.115c-19.762-.9-38.09-11.285-38.078-32.387l-2.457-.095z"
    />
    <path
      style={{
        fill: "none",
        stroke: backgroundColor,
        strokeWidth: 1.865,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="M32.269 47.224c-.61 3.48-1.56 8.221-1.984 10.3"
    />
    <path
      style={{
        fill: "none",
        stroke: backgroundColor,
        strokeWidth: 2.165,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="m39.545 50.012-.567 10.961"
    />
    <path
      style={{
        fill: "none",
        stroke: backgroundColor,
        strokeWidth: 1.565,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="m48.144 50.012.094 15.402"
    />
    <path
      style={{
        fill: "none",
        stroke: backgroundColor,
        strokeWidth: 2.165,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="M1.37 35.46c5.092 29.213 24.199 39.042 44.695 39.593 17.332.465 41.03-7.303 48.76-39.782"
    />
    <path
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: backgroundColor,
        strokeWidth: ".264583px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="m16.11 64.659 5.009 30.143c.9 4.329 3.448 7.01 8.126 7.56l37.136-.095c6.677-.559 7.465-3.258 8.788-5.764l6.615-35.908c-8.144 9.027-20.771 14.6-32.133 14.428-12.034-.182-22.55-1.59-33.54-10.364zM16.555 31.288l9.005-.27-8.985-1.242zM16.63 24.451l.042 3.997 9.83-1.195-.053-1.432zM16.68 19.107l.033 4.21 9.723-1.464.033-1.432zM16.677 13.632v4.441l9.778-1.626v-1.203zM16.583 8.104l.047 4.488 9.806-1.498-.02-1.053z"
    />
    <path
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: backgroundColor,
        strokeWidth: ".264583px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="m26.458 6.923.047 23.954 11.859-.047.047-20.553-3.78-3.59zM44.175 5.93l-.047 1.843 9.97.095.094 1.37h-1.134l-.19 3.874 1.513.189c-3.595 5.745-7.924 14.002-7.607 16.82l28.395.472c-1.793-3.33-5.616-5.306-11.15-6.142l.047-11.197 1.418-.095-.237-3.685 7.182 6.237 1.7-1.087-5.716-8.08 3.59-1.89 5.009-.094-.19-4.44-4.299.046-.047 1.087-18.946-.094c-4.38.449-6.783 2.686-9.355 4.771zM92.934.592l-2.22 6.283-6.662 1.938 6.709 1.653 2.22 6.048 1.796-5.764 6.945-1.937-6.85-2.127zM80.886 21.475l4.536-1.276 1.654-4.488 1.748 4.583 4.157 1.134-4.866 1.417-.945 4.488-1.512-4.724z"
    />
  </svg>
)

export default SvgComponent
