import * as React from "react"

const SvgComponent = ({strokeColor="white", 
backgroundColor="#A50000",
width="70%", 
height="70%"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={480}
    height="120mm"
    viewBox="0 0 127 120"
      style={{
      height: height,
      width: width
    }}
  >
    <ellipse
      style={{
        opacity: 1,
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 4.4714,
        strokeLinecap: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      cx={63.833}
      cy={8.301}
      rx={6.105}
      ry={5.491}
    />
    <ellipse
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 4.4714,
        strokeLinecap: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      cx={117.798}
      cy={60.822}
      rx={6.105}
      ry={5.491}
    />
    <ellipse
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 4.4714,
        strokeLinecap: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      cx={63.345}
      cy={112.387}
      rx={6.105}
      ry={5.491}
    />
    <ellipse
      style={{
        fill: backgroundColor,
        fillOpacity: 1,
        stroke: strokeColor,
        strokeWidth: 4.4714,
        strokeLinecap: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "fill markers stroke",
      }}
      cx={8.892}
      cy={60.822}
      rx={6.105}
      ry={5.491}
    />
    <path
      style={{
        fill: backgroundColor,
        stroke: strokeColor,
        strokeWidth: 4.4714,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="M11.761 43.932c0 .12-.977 3.163-.977 3.163M14.752 37.188l-1.099 2.148M18.415 31.1l-1.099 2.03M23.543 25.37 22.2 26.923M29.159 20.118l-1.465 1.433M35.752 15.94l-1.831 1.194M40.636 13.673ZM40.636 13.673ZM42.528 12.718l-1.892.955M50.037 10.33l-2.32.717M78.362 10.689l2.137.835M85.2 13.195l2.502 1.313M92.28 16.836l2.137 1.373M98.507 21.073l1.77 1.612M103.94 26.325l1.77 1.79M108.458 32.115l1.77 2.029M112.303 38.44l1.405 2.448M115.356 45.603l.732 2.327M115.478 75.265l-.61 2.208M112.975 82.188l-.977 2.149M109.251 88.753l-1.099 1.79M104.612 94.721l-1.343 1.492M99.056 100.152l-1.526 1.254M92.89 104.569l-1.77 1.074M85.932 108.15l-1.71.895M78.911 110.895l-1.953.537M48.022 110.895l-1.77-.716M40.697 108.15l-1.832-.896M34.104 104.808l-1.71-1.075M28 100.391l-2.015-1.79M22.688 95.318l-1.587-1.73M17.743 89.29l-1.098-1.611M14.142 82.665l-.977-1.67M11.09 75.981l-.611-2.03"
    />
    <path
      style={{
        fill: backgroundColor,
        stroke: strokeColor,
        strokeWidth: 4.4714,
        strokeLinecap: "butt",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="M30.164 72.58V40.844c0-1.42 64.94.14 65.526-.338 1.35-1.1.474 44.68.259 42.624l-35.137.084"
    />
    <path
      style={{
        fill: backgroundColor,
        stroke: strokeColor,
        strokeWidth: 4.4714,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="m75.867 47.212-2.105 4.182M82.5 53.478l-4.076 2.474M80.496 60.847l4.817 1.68"
    />
    <path
      style={{
        fill: backgroundColor,
        stroke: strokeColor,
        strokeWidth: 4.4714,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="m33.358 78.235 5.008 6.92 5.007-3.207-4.49-6.668z"
    />
    <path
      style={{
        fill: backgroundColor,
        stroke: strokeColor,
        strokeWidth: 4.4714,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="m43.373 70.3-5.576 3.413 6.654 9.758 5.983-4.297zM57.026 87.679l-9.004-6.655M44.193 71.208c8.68-3.93 15.186-19.066 15.173-22.683l18.13 25.827c-13.818-1.242-20.814.728-27.54 4.136z"
    />
    <path
      style={{
        fill: backgroundColor,
        stroke: strokeColor,
        strokeWidth: 4.4714,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="m71.172 60.088-2.418 1.435"
    />
  </svg>
)

export default SvgComponent
